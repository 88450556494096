import { useEffect } from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TextPlannet } from "."
import { Box } from "@mui/material"

const Celebremos = ({bkImage}) => {
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
    <ParallaxBannerLayer scale={[0.99, 1]} translateX={[0, 1]} image={bkImage} speed={-5} />
    <ParallaxBannerLayer  translateY={[50, -15]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "45px",
                    color: "white",
                    textShadow: "0px 6px 15px white"
                }}>Celebremos</TextCarios>
                <TextPlannet sx={{
                    fontSize: "45px",
                    color: "white",
                    fontWeight: "bold",
                    textShadow: "0px 6px 15px white"
                }}>Juntos</TextPlannet>
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>)
}

export default Celebremos