import { Box, Grid } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextAdega, TextCarios, TextPlannet, TextRobotoReg, TextSouthwalles } from "."
import img from '../../../img/icono_soloadultos.png'
import fb from '../../../img/icono_fb.png'
import instagram from '../../../img//icono_instagram.png'
import { useEffect } from "react"

const Consideraciones = ({nombre_corto, hashtag=false}) => {
    useEffect(()=>{
        
        console.log("HASHTAG")
        console.log(hashtag)
    },[hashtag])
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer translateY={[25, 30]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "30px",
                    color: "#413524",
                    lineHeight: "0px",
                }}>Recuerdos</TextCarios>
                
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[20, 40]} sx={{width: "100%"}}>
            <Box sx={{display: "grid", justifyContent: "center", width: "100%"}}>
                <Box sx={{position: "relative", width: "100%"}}>
                    <Box sx={{
                        position: "absolute",
                        top: "300px",
                        width: "100%"
                    }}>
                        <Grid container>
                            <Grid xs={5} sx={{display: "grid", alignContent: "center", justifyContent: "center"}}>
                                <img src={fb} width="70px" height="70px"></img>
                            </Grid>
                            <Grid xs={2}></Grid>
                            <Grid xs={5} sx={{display: "grid", alignContent: "center", justifyContent: "center"}}>
                                <img src={instagram} width="70px" height="70px"></img>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{
                        position: "relative",
                        top: "248px"
                    }}>
                        <TextCarios sx={{
                            width: "100%",
                            fontSize: "28px",
                            color: "#c7a985",
                            padding: "20px",
                            lineHeight: "25px",
                        }}>
                            #{hashtag ? hashtag : `boda${nombre_corto}`}
                        </TextCarios>
                    </Box>
                </Box>
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            top: "135px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRobotoReg sx={{
                    fontSize: "13px",
                    fontWeight: "bold",

                    color: "#413524",
                    padding: "20px",
                    textTransform: "uppercase",
                    letterSpacing: "2px",
                    lineHeight: "25px",
                }}>
                    Etiquetanos en las publicaciones de nuestra boda que hagas en tus redes sociales utiliza nuestro hashtag
                </TextRobotoReg>
            </Box>
        </Box>
        <ParallaxBannerLayer  translateY={[50, 85]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "25px",
                    color: "#413524",
                    textShadow: "0px 6px 15px white"
                }}>Celebremos</TextCarios>
                <TextRobotoReg sx={{
                    fontSize: "20px",
                    color: "#413524",
                    // fontWeight: "bold",
                    letterSpacing: "2px",
                    textShadow: "0px 6px 15px white"
                }}>
                    Juntos
                </TextRobotoReg>
                {/* <TextPlannet sx={{
                    fontSize: "30px",
                    color: "#413524",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    textShadow: "0px 6px 15px white"
                }}>Juntos</TextPlannet> */}
            </Box>
        </ParallaxBannerLayer>    
    </ParallaxBanner>)

}

export default Consideraciones