import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import { ParallaxBanner } from "react-scroll-parallax";
import { TextCarios, TextRobotoReg } from ".";
import bkPadres from "../../../img/seccion_padres-removebg-preview.png"

const Confirmation = ({bkImage, guest, evento, open, setOpen}) => {
    const authContext = useContext(AuthContext);
    const { confirmarAsistencia, confirmado } = authContext;
    const handleOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };
    const [confirm, setConfirm] = useState(0);
    const [asistidos, setAsistidos] = useState(0);
  
    const aceptarAsistencia = (noAsistire = false) => {
      confirmarAsistencia(noAsistire ? 1010: asistidos)
      handleClose()
    }
  
    const onChange = e => {
      if(e?.target?.value && e?.target?.value > 0 && guest?.reservaciones){
        if(e?.target?.value <= guest?.reservaciones){
          setAsistidos(e.target.value)
        }
      } 
    }
  
    useEffect(()=>{if(guest) setConfirm(confirmado ? true: false) },[confirmado])
  
    useEffect(()=> {
      if(guest){
        setConfirm(guest?.confirm ? guest.confirm: 0)
        setAsistidos(guest?.reservaciones ? guest.reservaciones: 0)
      }  
    },[guest])
  
    return(<Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className='test-kdemslkamds'
        sx={{
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
          "& .MuiBackdrop-root":{
            background: "black",
            opacity: "0.88 !important"
          }
        }}
      >
        <Box className="ldkñldks" sx={{
          outline: "0px !important",
          paddingX: "15%",
          position: "relative"
        }}>
          <Close onClick={handleClose} sx={{
            cursor: "pointer",
            color: "white !important",
            position: "absolute",
            top: "0px",
            right: "30px"
          }}/>
          <Typography variant='h5' sx={{textAlign: "center", color:"white", fontFamily: "'Antic Didone'", fontSize: "30px", fontWeight: "bold"}}>¿Deseas confirmar tu asistencia?</Typography>
          <Typography variant='subtitle2' sx={{fontFamily: "'Antic Didone'", marginBottom: '30px', fontSize: "20px", textAlign: "center", color:"white"}}>
            Para nosotros es un día muy importante y es un gusto invitarte a que seas parte de ello.
          </Typography>
          <Grid container sx={{height: '170px', marginTop: "50px"}} spacing={2}>
            <Grid item xs={4} sx={{textAlign: 'center', padding: "0px !important", display: "grid", alignContent: "center"}}>
              <Button onClick={()=>{              
                if(asistidos && (asistidos-1) > 0 && guest?.reservaciones){
                  setAsistidos(asistidos-1)
                } 
              }}><Remove sx={{
                color: "white !important",
                background: "#5d5d5d99",
                padding: "5px 10px",
                height: "40px",
                width: "40px"
              }}/></Button>
            </Grid>
            <Grid item xs={4} sx={{textAlign: 'center', padding: "0px !important"}}>
              <Box sx={{display: "flex"}}>
                <TextField
                  variant='standard'
                  type='number'
                  value={asistidos}
                  onChange={onChange}
                  sx={{"& input":{color: "white !important", textAlign: "center"}}}
                /><p style={{display: "grid", alignContent: "center"}}>persona{asistidos>1?"s":null}</p>
              </Box>
            </Grid>
            <Grid item xs={4} sx={{textAlign: 'center', padding: "0px !important", display: "grid", alignContent: "center"}}>
              <Button onClick={()=>{              
                if(asistidos && asistidos > 0){
                  if(asistidos < guest?.reservaciones){
                    setAsistidos(asistidos+1)
                  }
                } 
              }}>
                <Add sx={{
                  color: "white !important",
                  background: "#5d5d5d99",
                  padding: "5px 10px",
                  height: "40px",
                  width: "40px"
                }}/>
              </Button>
            </Grid>
            <Grid item xs={12} sx={{
                  marginTop: "30px",
                  color: "white",
                  background: "#5d5d5d99",
                  alignSelf: "center",
                  textAlign: "center",
                  padding: "20px 10px",
                  borderRadius: "5px",
                }} onClick={() => aceptarAsistencia()} >
                Enviar
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{position: "relative"}}>
        <ParallaxBanner
          style={{height: "60vh", background: "black"}}
            layers={[{
                image: bkImage,
                opacity: [0.4, 0.6]
            },
            {
                translateY: [-30,-48],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                <Box
                    sx={{
                    display: "grid",
                    height: '50vh',
                    alignContent: "center",
                    justifyContent: "center"
                }}>
                    <Grid container sx={{width: '100vw', marginTop: "30%"}}>
                    <Grid item xs={12} sx={{display: "grid", justifyContent: 'center', alignContent: "center"}}> 
                        {/* <CardGiftcard sx={{fontSize: "80px", color: "white"}}/> */}
                        <TextCarios sx={{
                        textAlign: "center", 
                        color: "white",
                        fontSize: "28px !important",
                        // fontFamily: "'Sacramento'",
                        }}>
                        {confirm <= 0 && guest?.tipoInvitacion != 3 ? "Confirmar Asistencia":  guest?.tipoInvitacion == 3 ? "": "Gracias por confirmar"}
                        </TextCarios>
                    </Grid>
                    <Grid item xs={1} md={2} sx={{display: "grid", alignContent: "center"}}></Grid>
                    <Grid item xs={10} md={8}>
                    </Grid>
                    <Grid item xs={1} md={2} sx={{display: "grid", alignContent: "center"}}></Grid>
                    </Grid>
                    </Box>
                )
            },{
              translateY: [-12, -4],
              opacity:[0.2, 1.4],
                  scale: [0.8, 1.1],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (<Box
                  sx={{
                  display: "grid",
                  height: '50vh',
                  alignContent: "center",
                  justifyContent: "center"
                }}>
                  <TextRobotoReg sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    color: "white",
                    padding: "0px 20px",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    lineHeight: "25px",
                  }}>
                      Esperemos que nos acompañen en el inicio de nuestra vida como marido y mujer
                  </TextRobotoReg>
                </Box>)
              },{
                translateY: [28, 55],
                  shouldAlwaysCompleteAnimation: true,
                  opacity:[0.2, 1.4],
                  scale: [0.8, 1.1],
                  expanded: false,
                  children: (<Box
                    sx={{
                    display: "grid",
                    height: '50vh',
                    alignContent: "center",
                    justifyContent: "center"
                  }}>
                    <TextRobotoReg sx={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      color: "white",
                      padding: "0px 50px",
                      textTransform: "uppercase",
                      letterSpacing: "2px",
                      lineHeight: "20px",
                    }}>
                        Ayudanos confirmando tu asistencia a nuestra boda
                    </TextRobotoReg>
                  </Box>)
                },{
                  translateY: [10, 25],
                  opacity:[0.2, 1.4],
                  scale: [0.8, 1.1],
                    shouldAlwaysCompleteAnimation: true,
                    expanded: false,
                    children: (<Box
                      sx={{
                      display: "grid",
                      height: '50vh',
                      alignContent: "center",
                      justifyContent: "center",
                      minHeight: "250px",
                      padding: "20px"
                    }}>
                      {confirm <= 0 && guest?.tipoInvitacion != 3 ? (<Grid container>
                        <Grid item xs={5}>
                          <Box onClick={handleOpen} sx={{
                            background: "#bb8f62",
                            padding: "10px 20px",
                            borderRadius: "8px"
                          }}>
                            <TextRobotoReg sx={{
                              fontSize: "15px",
                              color: "white",
                              textDecoration: "uppercase"
                            }}>
                              Si, por nada me lo pierdo
                            </TextRobotoReg>
                          </Box>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5}>
                        <Box onClick={()=>{aceptarAsistencia(1010)}} sx={{
                            background: "#bb8f62",
                            padding: "10px 10px",
                            borderRadius: "8px"
                          }}>
                            <TextRobotoReg sx={{
                              fontSize: "15px",
                              color: "white",
                              textDecoration: "uppercase"
                            }}>
                              No podre asistir, lo lamento
                            </TextRobotoReg>
                          </Box>
                        </Grid>
                      </Grid>):(<Grid container></Grid>)}
                    </Box>)
                  }
            ]}
        />
      </Box>
    </Box>)
  }

export default Confirmation