import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import RoutePrivate from "./componentes/routes/routePrivate";
import Landing from "./componentes/routes/landing";
import Acceso from "./componentes/routes/acceso";
import Invitacion from "./componentes/pages/invitacion";
import AuthState from "./context/auth/authStates";
import MultimediaState from "./context/multimedia/multimediaState";

const rootElement = document.getElementById("root");
const token = localStorage.getItem('token');

render(<BrowserRouter>
    <AuthState>
      <MultimediaState>
        <Routes>
          <Route path="acceso"  element={<Acceso/>} />
          <Route path="codigo/:code"  element={<Acceso/>} />
          <Route path="codigo/m/:codeManually"  element={<Acceso/>} />
          <Route path="landing" element={<Landing refresh={true}/>} />
          <Route path="*" element={<Landing refresh={true}/>} />
          <Route path="invitacion/:festejado" element={
            <RoutePrivate token={token}><Invitacion/></RoutePrivate>}
          />
        </Routes>
      </MultimediaState>
    </AuthState>
  </BrowserRouter>,
  rootElement);



