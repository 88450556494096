import { Box, Typography } from "@mui/material"
import Banner from './Banner'
import { useContext, useEffect, useState } from "react"
import { MultimediaContext } from "../../../context";
import { extract_file } from "../../../functions";
import Frases from "./Frases";
import Padres from "./Padres";
import Invitado from "./Invitado";
import Recepcion from "./Recepcion";
import Dresscode from "./Dresscode";
import Obsequios from "./Obsequios";
import Confirmation from "./Confirmacion";
import Recuerdos from "./Recuerdos";
import Galeria from "./Galeria";
import PlayButton from "./Playbutton";

const Component = (data = []) => {
    const multimediaContext = useContext(MultimediaContext);
    const { obtener_filename, multimedia } = multimediaContext;

    const [secciones,   setSecciones]   = useState([])
    const [multimedia_, setMultimedia_] = useState([])
    const [evento, setEvento]           = useState({})
    const [invitado, setInvitado]       = useState({})
    const [fecha,  setFecha]            = useState("31 Mar 2024 17:30:00 UTC")
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        console.log(data)
        let evento = null
        if(data?.data?.evento)   evento = data.data.evento
        if(data?.data?.invitado) setInvitado(data.data.invitado)
        if(evento && evento?.apartados){
            setSecciones(evento.apartados)
            obtener_filename(evento._id)
            setEvento(evento)

            if(evento?.dtEvento){
                let fecha_epoch = evento?.dtEvento ? evento.dtEvento: null
                if(fecha_epoch){
                    var d = new Date(fecha_epoch); // The 0 there is the key, which sets the date to the epoch
                    setFecha(d)
                }
            }
        }
    },[data])

    useEffect(()=>{
        console.log(multimedia)
        let result = {}
        if(multimedia.length > 0){
            multimedia.map((m_, m_i) => {
                if(m_?.s_filename){
                    let file_ = extract_file(m_, multimedia)

                    result = {
                        ...result,
                        ...file_
                    }
                }

            })
        }

        setMultimedia_({...result})

    },[multimedia])

    return(<Box>
        {secciones["*button_play"] ? (<PlayButton/>): null}
        {secciones?.banner          ? (<Banner nombre_corto="Jeimi" bkImage={multimedia_?.banner ? multimedia_?.banner: null} data={data} deadline={fecha}/>): null}
        {secciones?.frases          ? (<Frases/>): null}
        {secciones?.padres          ? (<Padres evento={evento}/>): null}
        {secciones?.invitado        ? (<Invitado evento={evento} invitado={invitado} bkImage={multimedia_?.invitado ? multimedia_.invitado: null}/>): null}
        {secciones?.recepcion       ? (<Recepcion bkImage={multimedia_?.recepcion ? multimedia_.recepcion: null}/>): null}
        {secciones?.dresscode       ? (<Dresscode bkImage={multimedia_?.dresscode ? multimedia_.dresscode: null}/>): null}
        {secciones?.obsequios       ? (<Obsequios/>): null}
        {secciones?.confirmacion    ? (<Confirmation bkImage={multimedia_?.confirmacion ? multimedia_.confirmacion: null} guest={invitado} open={open} setOpen={setOpen}/>): null}
        {secciones?.galeria         ? (<Galeria bkImage={multimedia_?.galeria ? multimedia_.galeria: null}/>): null}
        {secciones?.recuerdos       ? (<Recuerdos/>): null}
        <Box sx={{background: "black", display: "grid", padding:"10px 10px"}}>
            <Typography sx={{fontFamily: "'robotoregular'", fontSize: "12px", textAlign: "center"}}>
                Desarrollado por <a href="https://convitte.com/" style={{
                    textDecoration: "none",
                    color: "#f6aaa6",
                    fontWeight: "700"
                }}>Convitte</a>
            </Typography>
        </Box>
    </Box>)
}

export default Component