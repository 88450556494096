import { Box } from "@mui/material";
import { ParallaxBanner, ParallaxBannerLayer, useParallax } from "react-scroll-parallax";
const App = ({bkImage}) => {
  // const parallaxRotateY = useParallax({
  //   translateY: [0, -1]
  // });

  const phrase = '"Te he amado con un amor eterno. por eso te atraje a mí con amor leal"'
  // const parallaxEasingLeft = useParallax({
  //   translateY: [0, 80],
  //   speed: 20
  // });

  return (<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
    <Box sx={{
      background: "black",
      "& div":{
        inset: "0px -300px !important"
      }
    }}>
      <ParallaxBannerLayer image={bkImage} translateX={[5, -5]} speed={-5} scale={[0.99, 1]}/>
    </Box>
    <ParallaxBannerLayer translateY={[30, 38]} speed={-5} scale={[0.99, 1]}>
        <p style={{
            letterSpacing: "4px",
            fontSize: "10px",
            width: "100%",
            textAlign: "center",
            lineHeight: "25px",
            fontFamily: "robotolight",
            textShadow: "1px 1px 1px black",
            color: "black"
        }}>{phrase.toUpperCase()}</p>
        <p style={{
            letterSpacing: "3px",
            fontSize: "9px",
            width: "100%",
            color: "black",
            textAlign: "end",
            textShadow: "1px 1px 1px black",
            padding: "8px",
            zIndex: 2,
            fontFamily: "robotolight"
        }}>JEREMÍAS 31:3</p>
      </ParallaxBannerLayer>
    </ParallaxBanner>
    // <div>
    //   <section style={{position: "relative", height: "100vh"}} className="bg-container">
    //     <div style={{
    //         position: "absolute",
    //         top: "20px",
    //         width: "100%",
    //         padding: "8px",

    //         zIndex: 2,

    //     }} ref={parallaxEasingLeft.ref}>
    //         <p style={{
    //             letterSpacing: "4px",
    //             fontSize: "10px",
    //             width: "100%",
    //             textAlign: "center",
    //             lineHeight: "25px",
    //             fontFamily: "robotolight"
    //         }}>{phrase.toUpperCase()}</p>
    //         <p style={{
    //             letterSpacing: "3px",
    //             fontSize: "9px",
    //             width: "100%",
    //             textAlign: "end",
    //             padding: "8px",
    //             zIndex: 2,
    //             fontFamily: "robotolight"
    //         }}>JEREMÍAS 31:3</p>
    //     </div>
    //     <img
    //       ref={parallaxRotateY.ref}
    //       src={bkImage}
    //       style={{
    //         objectPosition: "55% 56%",
    //         height: "100vh"
    //       }}
    //     />
    //     <div className="absolute-text">
    //       {/* <h1 ref={parallaxEasing.ref}>MEMBERSHIP WEBSITE</h1> */}
          
    //     </div>
    //   </section>
    // </div>
  );
};
export default App;