import { Box, styled } from "@mui/material"
import { BloqueAnimacion, Seccion, TextHomesmile, TextRobotoReg } from "."
import { useEffect } from "react"
import { Parallax, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

const TextoRecepcion = styled('p')({
    width: "100%", 
    textAlign: "center",
    fontFamily: "'lamora'",
    fontWeight: "bolder",
    fontSize: "34px !important",
    color: "black"
})

const ContenedorRecepcionTxt = styled('div')({
    maxWidth: "250px",
    minWidth: "250px",
    border: "3px solid white",
    background: "#ffffff3d",
    boxShadow: "0px 2px 14px -2px rgb(60 152 221)",
    borderRadius: "5px"
})

const VerMapaBtn = styled('div')({
    color: "black",
    background: "#f0e2ca",
    width: "fit-content",
    padding: "20px",
    borderRadius: "20px"
})

const Religiosa = ({bkImage}) => {
    let dir = "C. 5 de Mayo, Col. Del maestro, Montemorelos NL, 67500"
    let name_dir = 'Salón del Reino de los Testigos de Jehová'
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer opacity={[0.4, 0.6]} image={bkImage} speed={-20} />
        <ParallaxBannerLayer translateY={[22, 24]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <ContenedorRecepcionTxt>
                    <TextoRecepcion sx={{
                        letterSpacing: "4px"
                    }}>Discurso Bíblico</TextoRecepcion>
                </ContenedorRecepcionTxt>
                <TextoRecepcion sx={{marginTop: "20px"}}>5:30 P.M.</TextoRecepcion>

            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[47, 53]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextRobotoReg sx={{
                    fontSize: "20px",
                    // fontWeight: "bold",
                    letterSpacing: "4px",
                    padding: "0px 40px",
                    textShadow: "1px 1px 1px black"
                }}>
                    {name_dir.toUpperCase()}
                </TextRobotoReg>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[55, 60]}>
            <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                <VerMapaBtn onClick={e=>{
                    let map_dir = "https://www.google.com/maps/place/Sal%C3%B3n+del+Reino+de+los+Testigos+de+Jehov%C3%A1/@25.1948233,-99.8299913,17z/data=!3m1!4b1!4m6!3m5!1s0x867cd6d8eea3d81b:0x746ae068afee75b4!8m2!3d25.1948233!4d-99.8274164!16s%2Fg%2F11b6dgqz4j?entry=ttu"
                    var win = window.open(map_dir, '_blank');
                    // Cambiar el foco al nuevo tab (punto opcional)
                    win.focus();
                }}>
                    <TextRobotoReg sx={{fontSize: "20px", fontWeight: "bold"}}>VER MAPA</TextRobotoReg>
                </VerMapaBtn>
            </Box>
        </ParallaxBannerLayer>
        {/* <Box sx={{
            position: "absolute",
            bottom: "20px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRobotoReg sx={{
                    letterSpacing: "4px",
                    fontSize: "15px",
                    color: "black",
                    fontWeight: "bold",
                    textShadow: "1px 1px 10px black"
                }}>{dir.toUpperCase()}</TextRobotoReg>
            </Box>
        </Box> */}
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

const Recepcion = ({bkImage}) => {
    let dir = "C. José Maria paras y Ballesteros #800, Centro, Montemorelos N" 
    let nombre_dir  = 'Salón de eventos "Club de leones Montemorelos NL."'
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer opacity={[0.9, 0.9]} image={bkImage} speed={-20} />
        <ParallaxBannerLayer translateY={[22, 24]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <ContenedorRecepcionTxt>
                    <TextoRecepcion sx={{
                        letterSpacing: "4px"
                    }}>Recepción</TextoRecepcion>
                </ContenedorRecepcionTxt>
                <TextoRecepcion>7:00 P.M.</TextoRecepcion>

            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[47, 53]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextRobotoReg sx={{
                    fontSize: "20px",
                    // fontWeight: "bold",
                    letterSpacing: "4px",
                    padding: "0px 40px",
                    textShadow: "1px 1px 1px black"
                }}>
                    {nombre_dir.toUpperCase()}
                </TextRobotoReg>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[55, 60]}>
            <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                <VerMapaBtn>
                    <TextRobotoReg onClick={e=>{
                        let map_dir = "https://www.google.com/maps/place/Club+de+Leones+Montemorelos+A.C./@25.1852862,-99.842029,17z/data=!3m1!4b1!4m6!3m5!1s0x867cd71933f65505:0x35b3e3765e9b07e6!8m2!3d25.1852862!4d-99.8394541!16s%2Fg%2F1wb8v3m6?entry=ttu"
                        var win = window.open(map_dir, '_blank');
                        // Cambiar el foco al nuevo tab (punto opcional)
                        win.focus();
                    }} sx={{fontSize: "20px", fontWeight: "bold"}}>VER MAPA</TextRobotoReg>
                </VerMapaBtn>
            </Box>
        </ParallaxBannerLayer>
        {/* <Box sx={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRobotoReg sx={{
                    fontSize: "15px",
                    color: "black",
                    padding: "20px",
                    letterSpacing: "4px",
                    // fontWeight: "bold",
                    textShadow: "1px 1px 4px black"
                }}>{dir.toUpperCase()}</TextRobotoReg>
            </Box>
        </Box> */}
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

const Ubicaciones = ({imagenes}) => {
    return(<Box>
        <Religiosa bkImage={imagenes?.religiosa ? imagenes.religiosa: false}/>
        <Recepcion bkImage={imagenes?.recepcion ? imagenes.recepcion: false}/>
    </Box>)
}

export default Ubicaciones