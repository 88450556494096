import React, {useReducer} from 'react'
import authReducer from './authReducer'
import {AuthContext} from '../index'
import clientAxios  from '../../config/axios'
import tokenAuth from '../../config/tokenAuth'

import {
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    OBTENER_INVITADO,
    CONFIRMAR_INVITACION
} from '../../types'

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        mensaje: null,
        loading: true,
        informacion: null,
        confirmado: false
    }

    const [ state, dispatch ] = useReducer(authReducer, initialState)

    const registrarUsuario = async data => {
        try{
            const result = await clientAxios.post('/api/users', data)
            dispatch({
                type: REGISTRO_EXITOSO,
                payload: result.data
            })

            usuarioAutenticado()
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }

    const usuarioAutenticado = async (code = "") => {
        const token = localStorage.getItem('token');
        if(token){
            // TODO: funcion mandar token por headers
            tokenAuth(token);
        }
        try{
            const result = await clientAxios.get('/api/auth/code');
            if(result.data.success){
                let urlNombre = result.data.evento ? result.data.evento: false;
                let nombre = urlNombre?.festejados?.festejado ? urlNombre.festejados.festejado: 
                urlNombre?.festejados?.nombreCorto ? urlNombre.festejados.nombreCorto: "evento-especial";
                let nombreSinEspacios =  nombre.split(' ').join('')
                
                if(nombreSinEspacios && typeof nombreSinEspacios == "string"){
                    window.location.href = `/invitacion/${nombreSinEspacios.toLowerCase()}`
                }
            }

            dispatch({
                type: OBTENER_USUARIO,
                payload: result.data.user
            })
        } catch(err){
            console.log(err, "ERROR")
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: alert
            })
        }
    }

    const authInvitado = async () => {
        const token = localStorage.getItem('token');
        if(token){
            // TODO: funcion mandar token por headers
            tokenAuth(token);
        }

        try{
            const result = await clientAxios.get('/api/auth/code');
            dispatch({
                type: OBTENER_INVITADO,
                payload: result.data
            })
        } catch(err){
            const alert = {
                msg: err?.response?.data?.msg ? err.response.data.msg: err,
                alert: 'alerta-error'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: alert
            })
        }
    }

    const login = async code => {
        try{
            const result = await clientAxios.post('/api/auth/code', {code: code});
            console.log("klflkfdlñkdñlkñfldkl")
            
            dispatch({
                type: LOGIN_EXITOSO,
                payload: result.data
            })
            
            usuarioAutenticado(code)
        } catch(err){
            console.log(err)
            const alert = {
                msg: err?.response?.data?.msg ? err.response.data.msg: err,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }

    const cerrarSesion = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    const confirmarAsistencia = async (asistencia) => {
        if(asistencia){
            const result = await clientAxios.post('/api/guests/confirm', {confirm: asistencia})
            if(result?.data){
                let data = result.data
                if(data?.code == 200){
                    dispatch({
                        type: CONFIRMAR_INVITACION,
                        payload: true
                    })
                }
            }
        }
    }

    return(
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                informacion: state.informacion,
                loading: state.loading,
                confirmado: state.confirmado,
                authInvitado,
                registrarUsuario,
                usuarioAutenticado,
                login,
                confirmarAsistencia,
                cerrarSesion
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;