import '../styles/css/tmp-000-001.css'
import React, { useContext, useEffect, useState } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Grid, Typography, Box } from '@mui/material';
import { TextCarios, TextAdega } from '.';
import img_banner from "../../../img/banner.png"



const Banner = ({nombre_corto = "", bkImage, data = false, deadline="31 Dic 2024 17:30:00 UTC"}) => {
    const [state, setState] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0})
    const leading0 = (num) => { return num < 10 ? "0" + num : num }
    
    const getTimeUntil = (date_time) => {
      const time = Date.parse(date_time) - Date.parse(new Date());
      if (time < 0) { setState({ days: 0, hours: 0, minutes: 0, seconds: 0 }) }
      else {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        setState({ days, hours, minutes, seconds });
      }
    };
  
    const [width, setWidth] = React.useState(0)
    const ref = React.useRef([])
  
    useEffect(() => {
      getTimeUntil(deadline);
      setInterval(() => {
        getTimeUntil(deadline)

      }, 1000);
    },[])
  
    useEffect(() => {
      if(!ref?.current?.clientWidth) {
        return;
      }
      setWidth(ref?.current?.clientWidth);
    }, [ref?.current?.clientWidth]);
  
    try{
      let tmp = "tmp-1"
      
      const Circle = ({refComponent, unity, children}) => (
        <Grid sx={{
            display: "grid",
            alignContent: "center !important",
            justifyItems: "center !important",
        }} className='no-padding' xs={3}>
          <Box sx={{
            height: "50px",
            width: "50px",
            borderRadius: "50px",
            display: "grid",
            alignContent: "center !important",
            justifyContent: "center !important"
          }}>
            <Typography sx={{
              color: "#6e6d5f",
              // fontWeight: "bold",
              fontSize: "30px",
            //   fontFamily: "'Sacramento'",
              fontFamily: "'garetbook'"
            }} ref={refComponent}>{unity}</Typography>
          </Box>
          <Box sx={{
            fontSize: "14px !important",
            letterSpacing: "-1px",
            color: "black"
          }}>{children}</Box>
      </Grid>)
      
      return(<Box className='container'>
        <Box sx={{ position: "relative"}}>
          <ParallaxBanner
            style={{
              background: "#f7f5f4",
            }}
            className={`${tmp}-parallax-banner`} 
            layers={[{ 
              background: "#f7f5f4",
              speed: 0,
              easing: [1, 1, 1, 1],
              scale: [1, 1.2, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true, 
            },{
              translateY: [25, 36],
              opacity: [3, 0.7],
              scale: [1, 1, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
              expanded: true,
              children: (
                <div style={{
                  position: "absolute",
                  top: "18px",
                  color: "white",
                  textAlign: "center",
                  width: "100%",
                }}>
                  <TextAdega style={{
                    fontSize: "40px",
                    color: "#6e6d5f",
                  }}>¡Nos Casamos!</TextAdega>
                </div>
              ),
            },
            {
                translateY: [12, 14],
                opacity: [3, 0.7],
                scale: [1, 0.8, 'easeOutCubic'],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                  <div style={{
                    position: "absolute",
                    top: "0px",
                    color: "#6e6d5f",
                    textAlign: "center",
                    width: "100%",
                    height: "390px",
                    display: "grid",
                    alignContent: "center",
                    backgroundImage: `url("${img_banner}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}>
                    <TextCarios style={{
                      fontSize: "35px",
                      color: "#6e6d5f",
                    }}>{nombre_corto[1]}</TextCarios>
                    <TextCarios style={{
                      fontSize: "35px",
                      color: "#6e6d5f",
                    }}>&</TextCarios>
                    <TextCarios style={{
                      fontSize: "35px",
                      color: "#6e6d5f",
                    }}>{nombre_corto[0]}</TextCarios>
                  </div>
                ),
              },
            
            {
              translateY: [-12, -27],
              scale: [1, 1.05, 'easeOutCubic'],
              shouldAlwaysCompleteAnimation: true,
              expanded: false,
              children: (
                <div style={{
                  position: "absolute",
                  bottom: "20px",
                  color: "white",
                  textAlign: "center",
                  width: "100%",
                //   backdropFilter: "blur(4px)"
                }}>
                  <div style={{
                    display: "grid",
                    position: "relative",
                    top: "18px",
                    alignContent: "center",
                    justifyContent: "center"
                  }}>
                    <p style={{
                      color: "black",
                      background: "#f7f5f4",
                      width: "fit-content",
                      padding: "5px 20px"
                    }}>FALTAN</p>
                  </div>
                  <Grid container spacing={1} className={`${tmp}-container-counter-grid-tmp-container-circles`}>
                    <Grid sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                    }} className='no-padding' item xl={1} lg={1} md={1} sm={1} xs={0} >
                    </Grid>
                    <Grid sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                    }} className='no-padding' item xl={10} lg={10} md={10} sm={10} xs={12} >
                      <Grid container sx={{
                        border: "2.5px solid #aa8a68",
                        padding: "15px 30px"
                      }}>
                        <Circle unity={leading0(state.days)}>Días</Circle>
                        <Circle unity={leading0(state.hours)}>Hrs</Circle>
                        <Circle unity={leading0(state.minutes)}>Min</Circle>
                        <Circle unity={leading0(state.seconds)}>Seg</Circle>
                      </Grid>
                    </Grid>
                    <Grid className='no-padding' item xl={1} lg={1} md={1} sm={1} xs={0}></Grid>
                  </Grid>
                </div>
              ),
            },
          ]}
          >
          </ParallaxBanner>
        </Box>
      </Box>)
    }catch(err){
      console.log(err)
    }
}

export default Banner