import { useEffect } from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useSnapCarousel } from "react-snap-carousel"
import { TextCarios, TextRobotoReg, VerMapa } from "."
import { Box, Grid, styled } from "@mui/material"
import bkPadres from "../../../img/seccion_padres-removebg-preview.png"
import ico_tel from "../../../img/tel.png"
import hotel_op1 from '../../../img/amazon-removebg-preview.png'
import hotel_op2 from '../../../img/BBVA IMA.png'
import hotel_op3 from '../../../img/normal.png'
import hotel_op4 from '../../../img/sobre-removebg-preview.png'

import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, LocationCity, Star } from "@mui/icons-material"

const Obsequios = ({bkImage, n_cuenta}) => {
    let carousel_adjust = {
        backgroundColor: 'transparent',
        fontSize: '50px',
        width: '100vw',
        height: '100vh',
        flexShrink: 0,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    let img_adjust = {
        height: "100vw",
        width: "100vw",
        maxWidth: "210px",
        maxHeight: "210px",
        objectFit: "contain"
    }

    let div_adjust = {
        paddingTop: "40px",
        height: "100vh",
        marginBottom: "5px",
        justifyItems: "center",
        display: "grid",
        alignContent: "start"
    }

    let ButtonActionCarousel = styled('div')({
        color: "black"
    })

    const Slide = ({titulo="", src=false, url="", desc="", cuenta}) => {
        return(<li style={carousel_adjust}>
            <Box sx={div_adjust}>
                <TextRobotoReg sx={{
                    fontSize: "17px",
                    padding: "20px",
                    textTransform: "uppercase",
                    marginTop: "10px",
                    width: "70vw",
                    marginBottom: "5px",
                    borderBottom: "3px solid #b1a38a"
                }}>
                    {titulo}
                </TextRobotoReg>
                <Box sx={{position: "relative"}}>
                    {src ? (<img src={src} style={img_adjust}/>): null}
                </Box>
                <TextRobotoReg sx={{fontSize: "15px",textTransform: "uppercase", fontWeight: "500", width: "65vw"}}>
                    {desc}
                </TextRobotoReg>
                <TextRobotoReg sx={{
                    fontSize: "20px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    width: "65vw",
                    marginTop: "20px"

                }}>
                    {cuenta}
                </TextRobotoReg>
                {url ? (<Box sx={{
                    color: "black",
                    background: "#f0e2ca",
                    fontSize: "20px",
                    marginTop: "30px",
                    width: "fit-content",
                    padding: "20px",
                    fontFamily: "robotoregular",
                    borderRadius: "20px",
                    textTransform: "uppercase"
                }} onClick={e=>{
                    window.open(url, '_blank')
                }}>
                    Ver regalos 
                </Box>): null}
            </Box>
        </li>)
    }

    const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();
  return (
    <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <Box sx={{
                background: "black",
            "& div":{
                inset: "-15px -90px !important"
            }
        }}>
            <ParallaxBannerLayer translateX={[-5,-5]} image={bkPadres} speed={-5} opacity={[0.3, 0.4]}/>
        </Box>
        <ParallaxBannerLayer>
            <Box>
                <ul
                    ref={scrollRef}
                    style={{
                    display: 'flex',
                    overflow: 'auto',
                    scrollSnapType: 'x mandatory'
                    }}
                >
                    <li style={{...carousel_adjust, position: "relative"}}>
                        <img
                            src={bkImage}
                            style={{width: "100vw", height:'100vh', objectFit: "cover"}}
                        />
                        <TextCarios sx={{
                            position: "absolute",
                            textShadow: "0px 2px 13px white",
                            top: "40px",
                            color: "white",
                            fontSize: "40px"
                        }}>
                            Obsequios
                        </TextCarios>
                    </li>
                    <Slide 
                        src={hotel_op4}
                        titulo= "si deseas tener un detalle con nosotros puedes hacerlo a través de las siguientes maneras"
                        desc="Lluvia de sobres"
                    />
                    <Slide 
                        src={hotel_op2}
                        favorito={true}
                        titulo= "Transferencia bancaria"
                        desc="Jacqueline Elizabeth Villanueva Alanís"
                        cuenta={n_cuenta ? n_cuenta: "not found"}
                    />
                    <Slide 
                        src={hotel_op1}
                        favorito={true}
                        titulo= "Mesa de regalos"
                        url="https://www.amazon.com.mx/wedding/share/bodajacquelineymiguel"
                    />
                    <Slide 
                        src={hotel_op3}
                        favorito={true}
                        titulo= "Mesa de regalos"
                        url="https://whatshome.net//eCommerce.php?a=ppsog6j9i8tvb2xymlj93c1934&b=j9i853c324#nbb"
                    />
                    
                </ul>
                <Grid container sx={{
                        position: "absolute",
                        top: "40%"
                    }}>
                        <Grid item xs={6}>
                            <ButtonActionCarousel onClick={() => prev()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginLeft: "10px"
                                }}>
                                    <KeyboardDoubleArrowLeft sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                        <Grid item xs={6} sx={{display: "grid", justifyContent: "end"}}>
                            <ButtonActionCarousel sx={{
                                textAlign: "end"
                            }} onClick={() => next()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginRight: "10px"
                                }}>
                                    <KeyboardDoubleArrowRight sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                    </Grid>
                    {/* <div>{activePageIndex + 1} / {pages.length}</div> */}
            </Box>
        </ParallaxBannerLayer>

      
    </ParallaxBanner>
    );
}

export default Obsequios