import { Box, Grid, styled } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TextRoboto, TextRobotoReg, TextSouthwalles } from "."
import traje from '../../../img/traje_adkar.png'
import vestido from '../../../img/vestido_adkar.png'

const Dresscode = ({bkImage}) => {
    return(<ParallaxBanner className="maxHeight" style={{
        aspectRatio: '2 / 1', background: "white" }}>
        {/* <ParallaxBannerLayer image={bkImage} speed={-5} /> */}
        <ParallaxBannerLayer opacity={[0, 1.5]} translateY={[25, 30]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "40px",
                    color: "#413524"
                }}>
                    Dresscode
                </TextCarios>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[35, 40]}>
            <TextRobotoReg sx={{
                fontSize: "26px",
                letterSpacing: "4px",
                textShadow: "1px 1px 7px white",
                height: "70px",
                color: "#413524",
                textTransform: "uppercase"
            }}>Formal</TextRobotoReg>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[45, 50]} >
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <Grid container>
                    <Grid item xs={6}>
                        <img src={traje} style={{
                            width: "100%",
                            padding: "20px",
                            maxWidth: "200px"
                        }}/>
                        <TextRobotoReg sx={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            letterSpacing: "5px",
                            height: "40px",
                            color: "#413524"
                        }}>
                            HOMBRES
                        </TextRobotoReg>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={vestido} style={{
                            width: "100%",
                            padding: "20px",
                            maxWidth: "200px"
                        }}/>
                         <TextRobotoReg sx={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            letterSpacing: "5px",
                            height: "40px",
                            color: "#413524"
                        }}>
                            MUJERES
                        </TextRobotoReg>
                    </Grid>
                </Grid>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[55, 60]} >
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextoSign>_+___________</TextoSign>        
                    </Grid>
                </Grid>
            </Box>
        </ParallaxBannerLayer>
      </ParallaxBanner>)
}

export default Dresscode

const TextoSign = styled('p')({
    fontSize: "40px",
    width: "100%",
    textAlign: "center",
    color: "#313131",
    fontFamily: "klcupid",
    fontWeight: "bold",
    transform: "rotate(90deg)"
})
