import { Box } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextAdega, TextCarios, TextRobotoReg, TextSouthwalles } from "."

const Consideraciones = ({bkImage}) => {
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer scaleX={[0.99, 1]} image={bkImage} speed={-5} />
        <ParallaxBannerLayer translateY={[20, 40]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "40px",
                    color: "white",
                    lineHeight: "0px",
                    textShadow: "0px 6px 15px #f17f35"
                }}>Consideraciones</TextCarios>
                
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            bottom: "15px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRobotoReg sx={{
                    fontSize: "15px",
                    color: "white",
                    fontWeight: "bold",
                    padding: "20px",
                    textTransform: "uppercase",
                    letterSpacing: "4px",
                    lineHeight: "25px",
                    // fontWeight: "bold",
                    textShadow: "1px 1px 4px black"
                }}>
                    Deseamos que sea un dia  inolvidable como lo hemos planeado,
                    ayúdanos a hacerlo posible
                </TextRobotoReg>
                <TextRobotoReg sx={{
                    fontSize: "35px",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "10px",
                    textShadow: "1px 1px 10px black",
                    letterSpacing: "4px",
                    // fontWeight: "bold",
                    textShadow: "1px 1px 4px black"
                }}>NO NIÑOS</TextRobotoReg>
            </Box>
        </Box>
    </ParallaxBanner>)
}

export default Consideraciones