import React, { useEffect, useState } from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { Box } from '@mui/material';
import styled from 'styled-components';
import flores from "./assets/flores.png"
import { TextRobotoReg, TxtSubTitles } from './Textos';

const Seccion = styled('div')({
    height: "100vh",
    background: "#f7f3ed"
})

const TextoRegular = styled('p')({
    letterSpacing: "4px",
    fontSize: "13px",
    width: "100%",
    textAlign: "center",
    padding: "10px",
    color: "#030100",
    fontFamily: "robotoregular"
})

const TextoThin = styled('p')({
    letterSpacing: "3px",
    fontSize: "9px",
    width: "100%",
    textAlign: "end",
    paddingRight: "20px",
    zIndex: 2,
    color: "#030100",
    fontFamily: "robotolight",
    fontWeight: "bold"
})

const TextoLigth = styled('p')({
    letterSpacing: "3px",
    fontSize: "12px",
    width: "100%",
    textAlign: "center",
    color: "#030100",
    fontFamily: "robotolight",
    fontWeight: "bold"
})

const TextoSign = styled('p')({
    fontSize: "40px",
    width: "100%",
    textAlign: "center",
    color: "#313131",
    fontFamily: "klcupid",
    fontWeight: "bold"
})

const TextoPadres = styled('p')({
    fontFamily: "'amazingglight'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

const Padres = ({evento, sx}) => {
    const [padres, setPadres] = useState({})

    useEffect(()=>{
        if(evento?.padres){
            setPadres(evento.padres)
        }
    },[evento])

    try{
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "#F8F7F5", position: "relative" }}>
            <ParallaxBannerLayer opacity={[0, 1.5]} translateY={[28,38]} speed={-5}>
                <TxtSubTitles sx={{
                    padding: "10px 20px !important",
                    letterSpacing: "4px",
                    fontSize: "20px",
                    color: "black",
                    fontWeight: "bold"
                }}>
                    CON LA BENDICIÓN DE DIOS
                </TxtSubTitles>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[-0.5, 2.5]} translateY={[34,44]} speed={-5}>
                <Box sx={{margin: "0px 10px"}}>
                    <TextoSign>_______+_______</TextoSign>
                </Box>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[0, 1.5]} translateY={[36,46]} speed={-5}>
                <TxtSubTitles sx={{
                    fontSize: "20px !important",
                    color: "black",
                    fontWeight: "bold"
                }}>
                    Y EN COMPAÑÍA DE MIS PADRES
                    </TxtSubTitles>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[2.1, -0.2]} translateX={[-50,50]} translateY={[49,49]} speed={-5}>
                <TxtSubTitles sx={{
                    letterSpacing: "3px",
                    marginBottom: "5px",
                    color: "black",
                    fontSize: "14px",
                }}>{padres?.padre?padres.padre.toUpperCase():""}</TxtSubTitles>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[2.1, -0.2]} translateY={[53,53]} speed={-5}>
                <TxtSubTitles sx={{
                    letterSpacing: "3px",
                    marginBottom: "5px",
                    color: "black",
                    fontSize: "14px",
                }}>&</TxtSubTitles>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer opacity={[2.1, -0.2]} translateX={[50,-50]} translateY={[56,56]} speed={-5}>
                <TxtSubTitles sx={{
                    letterSpacing: "3px",
                    marginBottom: "5px",
                    color: "black",
                    fontSize: "14px",
                }}>{padres?.madre ? padres.madre.toUpperCase(): ""}</TxtSubTitles>
            </ParallaxBannerLayer>
            <Box sx={{
                position: "absolute",
                bottom: 0,
                right: 0
            }}>
                <img src={flores} style={{height: "220px", width: "70px"}}/>
            </Box>
        </ParallaxBanner>
    )
    }catch(err){ console.log(err)}
  }

  export default Padres