export const REGISTRO_EXITOSO       = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR         =   "REGISTRO_ERROR";
export const OBTENER_USUARIO        =  "OBTENER_USUARIO";
export const OBTENER_INVITADO       =  "OBTENER_INVITADO";
export const LOGIN_EXITOSO          =    "LOGIN_EXITOSO";
export const LOGIN_ERROR            =      "LOGIN_ERROR";
export const CERRAR_SESION          =    "CERRAR_SESION";
export const CONFIRMAR_INVITACION   =    "CONFIRMAR_INVITACION";

// MULTIMEDIA
export const OBTENER_MULTIMEDIA     = "OBTENER_MULTIMEDIA";

// PASES
export const OBTENER_PASES          = "OBTENER_PASES";
