import TextoBiblico from "./TextoBiblico";
import Dresscode from "./Dresscode";
import Obsequios from "./Obsequios";
import Confirmation from "./Confirmacion";
import Banner from './Banner'
import Padres from "./Padres";
import Invitado from "./Invitado";
import Consideraciones from "./Consideraciones";
import Recuerdos from "./Recuerdos";
import Ubicaciones from "./Ubicaciones";
import { Box, Typography, styled } from "@mui/material"
import React, { useContext, useEffect, useState } from "react";
import { MultimediaContext } from "../../../context";
import { ParallaxBanner } from "react-scroll-parallax";
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import ReactAudioPlayer from 'react-audio-player';
import audio from '../../../audio/audio-boda-2.mp3';
import '../../Template/styles/css/tmp-adkar.css'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Loading = () => {
  return(
    <Stack spacing={1} sx={{background: "#fff1e3", padding: "3vh"}}>
      <Skeleton variant="rounded" width="100%" height="10vh" />
      <Skeleton variant="rounded" width="100%" height="10vh" />
      <Skeleton variant="rounded" width="100%" height="30vh" />
      <Skeleton variant="rounded" width="100%" height="1vh" />
      <Skeleton variant="rounded" width="100%" height="1vh" />
      <Skeleton variant="rounded" width="100%" height="6vh" />
      <Skeleton variant="rounded" width="100%" height="30vh" />
      <Skeleton variant="rounded" width="100%" height="10vh" />
    </Stack>
  );
}

const PlayButton = ({nombre_corto="M&M"}) => {
    let tmp = "tmp-1"
    const [playView, setPlayView] = useState(true);
    const [playMusic, setPlay] = useState(true);
    const [short, setShort] = useState("");
    const [notificacion, setNotificacion] = useState(true);

    const onPlay = state =>{
        setPlay(state)
        setPlayView(true)
        if(!state){
            document.getElementById('audioFile').play()
        }else{
            document.getElementById('audioFile').pause()
        }
    }

    useEffect(()=>{
        setTimeout(()=>{
            setNotificacion(false)
        },5000)
    },[])

    return(<Box className={`${tmp}-container-names`} sx={{position: "sticky", top: "0px", zIndex: "15 !important"}}>
    <Box sx={{position: "absolute"}}>
        <Box className={`${tmp}-container-double-border-names`} sx={{boxShadow: "0px 3px 18px -7px black"}}>
        <Box className={`${tmp}-container-background-names`} sx={{cursor:"pointer"}} onClick={() => onPlay(!playMusic)}>
            <Box sx={{
                color:"black !important",
                fontFamily: "'robotoregular'",
                position: "absolute",
                left: "-70px",
                background: "white",
                top: "25px",
                padding: "5px 10px",
                width: "90px",
                borderRadius: "10px 0px 30px 10px",
                fontFamily: "serif",
                fontSize: "14px",
                letterSpacing: "-1px",
                boxShadow: "0px 0px 10px -2px black",
                display: notificacion ? "block":"none"

            }}>Toca para reproducir</Box>
            <Box sx={{display: "flex", alignItems: "center", padding: "0px 8px"}}>
                <Box sx={{
                    display: "grid",
                    justifyContent: "center",
                    alignContent: "center"
                }}>
                    {playMusic ? (<PlayCircleOutline sx={{ color: '#343333a1'}}/>):
                    (<PauseCircleOutline sx={{ color: '#343333a1'}}/>)}
                </Box>
                <ReactAudioPlayer
                    id="audioFile"
                    src={audio}
                    autoPlay={true}
                />
                <Typography className={`${tmp}-container-label-names`}>{nombre_corto}</Typography>
            </Box>
        </Box>
    </Box>
    </Box>
</Box>)
}

export const TextSouthwalles = styled('p')({
    fontFamily: "'southwalles'",
    letterSpacing: "6px",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextRobotoReg = styled('p')({
    fontSize: "13px",
    width: "100%",
    textAlign: "center",
    color: "#030100",
    fontFamily: "robotoregular"
})

export const Seccion = styled('div')({
    height: "100vh",
    background: "#f7f3ed"
})

export const BloqueAnimacion = ({layers = []}) => {
    return(<Seccion>
        <ParallaxBanner
          layers={layers}
          className="maxHeight"
        />
    </Seccion>)
}

export const TextAmazing = styled('p')({
    fontFamily: "'amazingglight'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextHomesmile = styled('p')({
    fontFamily: "'homesmile'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextPlanner = styled('p')({
    fontFamily: "'planner'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextRoboto = styled('p')({
    fontFamily: "'lamora'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextCarios = styled('p')({
    fontFamily: "'carios'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextPlannet = styled('p')({
    fontFamily: "'plannet'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextAdega = styled('p')({
    fontFamily: "'adega'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const VerMapa = (url) => {  
    const redirectMap = () =>{
        console.log(url)
    }
    const VerMapaBtn = styled('div')({
        color: "black",
        background: "#f0e2ca",
        fontSize: "20px",
        marginTop: "30px",
        width: "fit-content",
        padding: "20px",
        fontFamily: "robotoregular",
        borderRadius: "20px"
    })

    return(<VerMapaBtn onClick={()=> window.open(url, '_blank')}>VER MAPA</VerMapaBtn>)
}


const Component = ({data = []}) => {
    const multimediaContext = useContext(MultimediaContext);
    const { obtener_filename, multimedia } = multimediaContext;

    const [multimedia_, setMultimedia_] = useState([])
    const [apartados, setApartados] = useState([])
    const [open, setOpen] = useState(false)
    const [nombre_corto, setNombre] = useState("")
    const [siglas, setSiglas] = useState("")
    const [n_cuenta, setNcuenta] = useState("")
    const [hashtag, setHashtag] = useState(false)
    const [secciones,   setSecciones]   = useState([])
    const [evento, setEvento]   = useState(null)
    const [plantilla,   setPlantilla]   = useState({})

    useEffect(()=>{
        if(data?.evento?.festejados){
            let festejados = data.evento.festejados
            setHashtag(data?.hashtag ? data.hashtag: false)
            let novia_nombre = festejados.novia, novio_nombre = festejados.novio
            let sigla_novia = novia_nombre.substr(0, 1), sigla_novio = novio_nombre.substr(0, 1)

            setSiglas(`${sigla_novia}&${sigla_novio}`)
            setNombre([festejados.novio, festejados.novia])
        }
        obtener_filename(data.evento._id)
        
        if(data?.secciones?.length > 0){
            setSecciones(data.secciones)
        }

        if(data?.evento){
            let evento = data.evento
            setEvento(evento)
            if(evento?.n_cuenta){
                setNcuenta(evento.n_cuenta)
            }

            if(evento?.apartados){
                setApartados(evento.apartados)
            }
        }
    },[data])

    useEffect(()=>{
        let array_secciones = {}
        if(secciones.length>0){
            secciones.map(s_ =>{
                let name = s_.name

                switch(s_.name){
                    case "banner":
                        array_secciones["b_banner"] = true
                        break;
                    case "padres":
                        array_secciones["b_padres"] = true
                        break;
                    case "invitado":
                        array_secciones["b_invitado"] = true
                        break;
                    case "iglesia":
                        array_secciones["b_invitacion"] = true
                        break;
                    case "dresscode":
                        array_secciones["dresscode"] = true
                        break;
                    case "obsequios":
                        array_secciones["b_obsequios"] = true
                        break;
                    case "consideraciones":
                        array_secciones["b_consideraciones"] = true
                        break;
                    case "recuerdos":
                        array_secciones["b_recuerdos"] = true
                        break;
                    case "confirmacion":
                        array_secciones["b_confirmacion"] = true
                        break;
                    case "texto-biblico":
                        array_secciones["texto_biblico"] = true
                        break;
                    case "obsequios":
                        array_secciones["obsequios"] = true
                        break;
                    default:
                        break;
                }
            })
        }
        setPlantilla(array_secciones)
    },[secciones])

    useEffect(()=>{
        console.log(plantilla)
    },[plantilla])

    useEffect(()=>{
        console.log(multimedia)
        if(multimedia?.length > 0){
            let result = {}
            multimedia.map((m_, m_i) => {
                if(m_?.s_filename){
                    let filename = m_?.s_filename, name = filename.split(".")
                    if(name[0].toLowerCase() == "iglesia"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            iglesia: array_multimedia.s_url
                        }
                    }
                    if(name[0].toLowerCase() == "recepcion"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            recepcion: array_multimedia.s_url
                        }
                    }
                    if(name[0].toLowerCase() == "texto-biblico"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            texto_biblico: array_multimedia.s_url
                        }
                    }
                    if(name[0].toLowerCase() == "obsequios"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            obsequios: array_multimedia.s_url
                        }
                    }
                    if(name[0].toLowerCase() == "confirmacion"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            confirmacion: array_multimedia.s_url
                        }
                    }
                }
            })
            setMultimedia_({...result})

        }
        
    },[multimedia])

    return(<Box>
        <PlayButton nombre_corto={siglas}/>
        {apartados.banner               ? (<Banner deadline={new Date(evento.dtEvento).toString()} nombre_corto={nombre_corto} bkImage={multimedia_.banner}/>):null}
        {apartados?.texto_biblico       ? (<TextoBiblico bkImage={multimedia_.texto_biblico}/>):null}
        {apartados.padres               ? (<Padres evento={data.evento}/>): null}
        {apartados.invitado             ? (<Invitado invitado={data.invitado}/>): null}
        {apartados.ubicaciones          ? (<Ubicaciones imagenes={{iglesia: multimedia_.iglesia, recepcion: multimedia_.recepcion}}/>): null}
        {apartados?.dresscode           ? (<Dresscode/>): null}
        {apartados?.obsequios           ? (<Obsequios bkImage={multimedia_.obsequios} n_cuenta={n_cuenta}/>): null}
        {apartados?.consideraciones     ? (<Consideraciones/>): null}
        {apartados?.confirmacion        ? (<Confirmation bkImage={multimedia_.confirmacion} guest={data.invitado} open={open} setOpen={setOpen}/>):null}
        {apartados?.recuerdos           ? (<Recuerdos nombre_corto={siglas} hashtag={hashtag}/>): null}
        <Box sx={{background: "black", display: "grid", padding:"10px 10px"}}>
            <Typography sx={{fontFamily: "'robotoregular'", fontSize: "12px", textAlign: "center"}}>
                Desarrollado por <a href="https://convitte.com/" style={{
                    textDecoration: "none",
                    color: "#f6aaa6",
                    fontWeight: "700"
                }}>Convitte</a>
            </Typography>
        </Box>
    </Box>)
}

export default Component