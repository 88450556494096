import { Box, Grid } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useEffect, useState } from "react"
import { TextAmazing, TextRoboto, TxtSubTitles } from "./Textos"

const Invitado = ({bkImage, invitado, evento}) => {
    const [nombre, setNombre] = useState("")
    const [dia, setDia]     = useState("01")
    const [mes, setMes]     = useState("01")
    const [anio, setAnio]   = useState("2024")

    const [reservaciones, setReservacion] = useState(0)
    useEffect(()=>{
        console.log(evento)
        if(invitado?.nombre){
            setNombre(invitado.nombre)
        }

        if(invitado?.reservaciones){
            setReservacion(invitado.reservaciones)
        }
    },[invitado])

    useEffect(()=>{
        if(evento?.dtEvento){
            let date_get = new Date(evento.dtEvento)
            setDia(date_get.getDate())
            setMes(date_get.getMonth()+1)
            setAnio(date_get.getFullYear())
        }
    },[evento])

    let phrase = "NOS COMPLACE INVITARTE A SER PARTE DE ESTE GRAN DÍA"
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        {/* <ParallaxBannerLayer scaleX={[0.99, 1]} image={bkImage} speed={-5} /> */}
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[0, 0]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px"}}>
                <TxtSubTitles sx={{
                    color: "black",
                    fontSize: "17px !important",
                    padding: "8%"
                }}>{phrase.toUpperCase()}</TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[31, 31]}>
            <Grid container sx={{width: "100% !important"}}>
                {bkImage?.length > 0 ? bkImage.map((item_img, i_index) => {
                    return(<Grid item xs={4}>
                        <Box sx={{
                            padding: "10px 5px"
                        }}>
                            <img src={item_img} style={{
                                minHeight: "180px"
                            }} width="100%"/>
                        </Box>
                </Grid>)}):null}
            </Grid>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[45, 72]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px", position: "relative"}}>
                <Box sx={{
                    maxWidth: "280px",
                    minWidth: "280px",
                    padding: "0px",
                    height: "100px",
                    border: "2px solid black",
                    position: "relative"
                }}>
                    <Box sx={{
                        position: "absolute",
                        top: "40%",
                        zIndex:1,
                        background: "white",
                        width: "300px",
                        height: "20px",
                        left: "-14px"
                    }}></Box>
                    <TxtSubTitles sx={{
                        fontSize: "20px !important",
                        height: "34px",
                        padding: "10px",
                        zIndex:3,
                        bottom: 5,
                        textAlign: "center",
                        width: "100%",
                        top: 2,
                        position: "relative",
                        color: "black"
                    }}>{nombre.toUpperCase()}</TxtSubTitles>
                    <TxtSubTitles sx={{
                        fontSize: "16px !important",
                        margin: "0px",
                        padding: "0px",
                        width: "100%",
                        position: "absolute",
                        bottom: 5,
                        textAlign: "center",
                        zIndex:"4 !important",
                        paddingBottom: "10px",
                        color: "black"
                    }}>{`(${reservaciones})`}</TxtSubTitles>
                </Box>
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            bottom: "50px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                    
                <TxtSubTitles sx={{
                    color: "black",
                    padding: "0px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginBottom: "5px !important"
                }}>Nos vemos el día</TxtSubTitles>

                <TxtSubTitles sx={{
                    fontSize: "32px !important",
                    color: "black",
                    margin: "0px",
                    padding: "0px",
                    marginBottom: "15px !important"
                }}>{dia > 9 ? dia: `0${dia}`} | {mes > 9 ? mes: `0${mes}`} | {anio}</TxtSubTitles>
            </Box>
        </Box>
    </ParallaxBanner>)
}

export default Invitado