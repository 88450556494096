import { useEffect } from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useSnapCarousel } from "react-snap-carousel"
import { TextCarios, TextRobotoReg, TxtSubTitles } from "./Textos"
import { Box, Grid, styled } from "@mui/material"
import bkPadres from "../../../img/seccion_padres-removebg-preview.png"
import ico_tel from "../../../img/tel.png"
import hotel_op1 from '../../../img/amazon-removebg-preview.png'
import hotel_op2 from '../../../img/BBVA IMA.png'
import hotel_op3 from '../../../img/normal.png'
import hotel_op4 from '../../../img/sobre-removebg-preview.png'

import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, LocationCity, Star } from "@mui/icons-material"

const Obsequios = ({bkImage, n_cuenta}) => {
    return (<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "#F8F7F5" }}>
        <ParallaxBannerLayer translateY={[16, 16]} opacity={[0.4, 1.5]}>
            <TextCarios sx={{
                color: "black",
                padding: "0px",
                marginTop: "20px !important",
                fontSize: "50px"
            }}>Obsequios</TextCarios>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[22, 22]} opacity={[0.4, 1.5]}>
            <Box sx={{padding: "50px"}}>
                <TxtSubTitles sx={{color: "black", padding: "0px", marginTop: "20px !important", fontSize: "16px"}}>
                Su presencia y compañIa serA SIEMPRE MI MEJOR REGALO, SIN EMBARGO SI QUIEREN OBSEQUIARME ALGO MÁS, PUEDENHACERLO A TRAVÉS DE:
                </TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[37, 70]} opacity={[-0.5, 1.5]}>
            <Box sx={{height: "40vh", width: "100%", padding: "1vh", textAlign: "center"}}>
                <img src={hotel_op4} style={{width: "100%", height: "38vh", maxWidth: "320px", maxHeight: "300px"}}/>
            </Box>
            <TxtSubTitles sx={{color: "black", padding: "0px", fontSize: "16px"}}>
                Lluvia de sobres
            </TxtSubTitles>
        </ParallaxBannerLayer>
        {/* <ParallaxBannerLayer translateY={[62, 62]} opacity={[0.5, 1.5]}>
        </ParallaxBannerLayer> */}
    </ParallaxBanner>);
}

export default Obsequios