import { Box } from "@mui/material";
import { ParallaxBanner, ParallaxBannerLayer, useParallax } from "react-scroll-parallax";
const App = ({bkImage}) => {
  // const parallaxRotateY = useParallax({
  //   translateY: [0, -1]
  // });

  const phrase = 'Cuando te das cuenta de que quieres pasar el resto de tu vida con alguien, deseas que el resto de tu vida comience cuanto antes"'
  // const parallaxEasingLeft = useParallax({
  //   translateY: [0, 80],
  //   speed: 20
  // });

  return (<ParallaxBanner style={{ aspectRatio: '1 / 1', background: "black", height: "50vh"}}>
    <Box sx={{
      background: "black",
      "& div":{
        inset: "0px -300px !important"
      }
    }}>
      <ParallaxBannerLayer image={bkImage} opacity={[0.5, 0.8]} translateX={[5, -5]} />
    </Box>
      <ParallaxBannerLayer translateY={[30, 38]} speed={-5} scale={[0.99, 1]}>
      <p style={{
            fontSize: "65px",
            fontStyle: "italic",
            width: "100%",
            textAlign: "center",
            fontFamily: "robotoregular",
            color: "white",
            height: "60px"
        }}>''</p>
        <p style={{
            letterSpacing: "2px",
            wordSpacing: "1px",
            fontSize: "18px",
            fontStyle: "italic",
            width: "100%",
            textAlign: "center",
            lineHeight: "30px",
            fontFamily: "robotolight",
            color: "white",
            padding: "0px 40px 0px 40px"
        }}>{phrase}</p>
      </ParallaxBannerLayer>
    </ParallaxBanner>
    // <div>
    //   <section style={{position: "relative", height: "100vh"}} className="bg-container">
    //     <div style={{
    //         position: "absolute",
    //         top: "20px",
    //         width: "100%",
    //         padding: "8px",

    //         zIndex: 2,

    //     }} ref={parallaxEasingLeft.ref}>
    //         <p style={{
    //             letterSpacing: "4px",
    //             fontSize: "10px",
    //             width: "100%",
    //             textAlign: "center",
    //             lineHeight: "25px",
    //             fontFamily: "robotolight"
    //         }}>{phrase.toUpperCase()}</p>
    //         <p style={{
    //             letterSpacing: "3px",
    //             fontSize: "9px",
    //             width: "100%",
    //             textAlign: "end",
    //             padding: "8px",
    //             zIndex: 2,
    //             fontFamily: "robotolight"
    //         }}>JEREMÍAS 31:3</p>
    //     </div>
    //     <img
    //       ref={parallaxRotateY.ref}
    //       src={bkImage}
    //       style={{
    //         objectPosition: "55% 56%",
    //         height: "100vh"
    //       }}
    //     />
    //     <div className="absolute-text">
    //       {/* <h1 ref={parallaxEasing.ref}>MEMBERSHIP WEBSITE</h1> */}
          
    //     </div>
    //   </section>
    // </div>
  );
};
export default App;