import React from 'react';
import { Navigate} from 'react-router-dom'
import tokenAuth from '../../config/tokenAuth';

const ProtectedRoute = ({ path, token, children }) => {
    if(!token){
        return <Navigate to="/landing" replace />;
    }else{
        tokenAuth(token)
    }
    
    return children;
  };


export default ProtectedRoute;