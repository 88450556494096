import { styled } from "@mui/material";

export const TextRobotoReg = styled('p')({
    fontSize: "13px",
    width: "100%",
    textAlign: "center",
    color: "#030100",
    fontFamily: "robotoregular"
})

export const TxtSubTitles = styled("p")({
    textTransform: "uppercase",
    textAlign: "center",
    padding: "10%",
    fontFamily: "'Belleza'",
    fontSize: "22px",
    letterSpacing: "2px",
    margin: "0px !important"
})

export const TextAmazing = styled('p')({
    fontFamily: "'amazingglight'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextRoboto = styled('p')({
    fontFamily: "'lamora'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextSouthwalles = styled('p')({
    fontFamily: "'southwalles'",
    letterSpacing: "6px",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextCarios = styled('p')({
    fontFamily: "'Buff'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextBuff = styled('p')({
    fontFamily: "'Buff'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextGlas = styled('p')({
    fontFamily: "'Glad'",
    fontSize: "21px",
    textAlign: "center"
})
