import React from 'react';
import Template from '../Template';
import { ParallaxProvider } from 'react-scroll-parallax';
import '../../App.css';
import '@fontsource/vibur';
import '@fontsource/antic-didone';
import '@fontsource/old-standard-tt';
import '@fontsource/sacramento';
import "@fontsource/trochut";
import "@fontsource/material-icons-outlined"

function Invitacion() {
  return (<Home/>);
}

const Home = () => (<ParallaxProvider>
  <Template/>
</ParallaxProvider>)

export default Invitacion;
