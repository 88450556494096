import { Box } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextAdega, TextCarios, TextRobotoReg, TextSouthwalles } from "."
import img from '../../../img/icono_soloadultos.png'

const Consideraciones = ({bkImage}) => {
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer scaleX={[0.99, 1]} image={bkImage} speed={-5} />
        <ParallaxBannerLayer translateY={[25, 30]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextCarios sx={{
                    fontSize: "30px",
                    color: "#413524",
                    lineHeight: "0px",
                }}>Consideraciones</TextCarios>
                
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[43, 55]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <div style={{
                    width: "400px",
                    backgroundImage: `url("${img}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "20vh",
                    display: "grid",
                    alignContent: "center",
                    justifyContent: "center"
                }}>
                    <TextRobotoReg sx={{
                        fontSize: "18px",
                        color: "#7a513d",
                        fontWeight: "bold",
                        padding: "20px",
                        textTransform: "uppercase",
                        letterSpacing: "3px",
                        lineHeight: "25px",
                    }}>
                        Solo adultos
                    </TextRobotoReg>
                </div>
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            top: "125px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRobotoReg sx={{
                    fontSize: "14px",
                    color: "#413524",
                    fontWeight: "bold",
                    padding: "0px 60px",
                    textTransform: "uppercase",
                    letterSpacing: "2px",
                    lineHeight: "25px",
                }}>
                    Queremos a sus hijos, pero ustedes necesitan una noche libre
                </TextRobotoReg>
            </Box>
        </Box>
        <Box sx={{
            position: "absolute",
            bottom: "55px",
            width: "100%",
            zIndex: "2",
            display: "grid",
            justifyItems: "center"
        }}>
            <Box sx={{width: "85%"}}>
                <TextRobotoReg sx={{
                    fontSize: "14px",
                    color: "#413524",
                    justifySelf: "center",
                    fontWeight: "bold",
                    padding: "0 40px",
                    textTransform: "uppercase",
                    letterSpacing: "2px",
                    lineHeight: "25px",
                }}>
                    Nos encantaría que esta celebración también la disfrutes tanto como nosotros
                </TextRobotoReg>
            </Box>
        </Box>
    </ParallaxBanner>)
}

export default Consideraciones