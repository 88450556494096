import { Box } from "@mui/material";
import { useEffect } from "react";
import { InfinitySpin } from "react-loader-spinner";

const Landing = ({refresh=false}) => {
    useEffect(()=>{
        if(refresh){
            setTimeout(()=>{
                window.location.href = "/acceso";
            }, 2000)
        }
    },[])

    return(<Box
      sx={{
        height: "100vh",
        display: "grid",
        alignContent: "center",
        justifyContent: "center"
    }}>
      <Box sx={{padding: '15vh 20%'}}>
        <Box sx={{
          "& svg":{
            width: "100% !important",
            height: "69vh !important"
          }
        }}>
          <InfinitySpin id="infinity"
            width='200'
            color="#e3c81d"
          />
        </Box>
      </Box>
    </Box>)
}

export default Landing;