import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import triangleTop from '../../../img/top-triangle.png'
import triangleBottom from '../../../img/bottom-triangle.png'
import { ParallaxBanner } from "react-scroll-parallax";
import { TextCarios } from ".";

const Confirmation = ({bkImage, guest, evento, open, setOpen}) => {
    const authContext = useContext(AuthContext);
    const { confirmarAsistencia, confirmado } = authContext;
    const handleOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };
    const [confirm, setConfirm] = useState(0);
    const [asistidos, setAsistidos] = useState(0);
  
    const aceptarAsistencia = (noAsistire = false) => {
      confirmarAsistencia(noAsistire ? 1010: asistidos)
      handleClose()
    }
  
    const onChange = e => {
      if(e?.target?.value && e?.target?.value > 0 && guest?.reservaciones){
        if(e?.target?.value <= guest?.reservaciones){
          setAsistidos(e.target.value)
        }
      } 
    }
  
    useEffect(()=>{if(guest) setConfirm(confirmado ? true: false) },[confirmado])
  
    useEffect(()=> {
      if(guest){
        setConfirm(guest?.confirm ? guest.confirm: 0)
        setAsistidos(guest?.reservaciones ? guest.reservaciones: 0)
      }  
    },[guest])
  
    return(<Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className='test-kdemslkamds'
        sx={{
          display: "grid",
          alignContent: "center",
          justifyContent: "center"
        }}
      >
        <Box sx={{outline: "0px !important", paddingX: "15%", position: "relative"}}>
          <Close onClick={handleClose} sx={{
            cursor: "pointer",
            color: "white !important",
            position: "absolute",
            top: "0px",
            right: "30px"
          }}/>
          <Typography variant='h5' sx={{textAlign: "center", color:"white", fontFamily: "'Antic Didone'", fontSize: "30px", fontWeight: "bold"}}>¿Deseas confirmar tu asistencia?</Typography>
          <Typography variant='subtitle2' sx={{fontFamily: "'Antic Didone'", marginBottom: '40px', fontSize: "20px", textAlign: "center", color:"white"}}>
            Para nosotros es un día muy importante y es un gusto invitarte a que seas parte de ello.
          </Typography>
          <Grid container sx={{height: '60px'}} spacing={2}>
            <Grid item xs={3} sx={{textAlign: 'center', padding: "0px !important"}}>
              <Button onClick={()=>{              
                if(asistidos && asistidos > 0){
                  if(asistidos < guest?.reservaciones){
                    setAsistidos(asistidos+1)
                  }
                } 
              }}><Add sx={{color: "white !important", background: "black", padding: "5px 10px"}}/></Button>
              <TextField
                variant='standard'
                type='number'
                value={asistidos}
                onChange={onChange}
                sx={{"& input":{color: "white !important", textAlign: "center"}}}
              />
              <Button onClick={()=>{              
                if(asistidos && (asistidos-1) > 0 && guest?.reservaciones){
                  setAsistidos(asistidos-1)
                } 
              }}><Remove sx={{color: "white !important", background: "black", padding: "5px 10px"}}/></Button>
            </Grid>
            <Grid item xs={9} sx={{textAlign: 'left', color: "white", display: "grid", alignContent: "center"}}>
              Número de personas
            </Grid>
            <Grid
              onClick={() => aceptarAsistencia()} 
              item xs={5} sx={{marginTop: "30px", color: "white", background: "black", textAlign: "center", padding: "20px 10px", borderRadius: "5px"}}>
              Asistire
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid  
              onClick={() => aceptarAsistencia(1010)} 
             item xs={5} sx={{marginTop: "30px", color: "white", background: "black", textAlign: "center", padding: "20px 10px", borderRadius: "5px"}}>
              No Asistire
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{position: "relative"}}>
        <ParallaxBanner
            layers={[{
                image: bkImage,
                translateY: [10, -10],
                opacity: [1, 1],
            },
            {
                translateY: [-60, 0],
                shouldAlwaysCompleteAnimation: true,
                expanded: false,
                children: (
                <Box
                    sx={{
                    display: "grid",
                    height: '100vh',
                    alignContent: "center",
                    justifyContent: "center"
                }}>
                    <Grid container sx={{width: '100vw', marginTop: "30%"}}>
                    <Grid item xs={12} sx={{display: "grid", justifyContent: 'center', alignContent: "center"}}> 
                        {/* <CardGiftcard sx={{fontSize: "80px", color: "white"}}/> */}
                        <TextCarios sx={{
                        textAlign: "center", 
                        color: "white",
                        textShadow: "0px 2px 3px black",
                        fontSize: "40px !important",
                        // fontFamily: "'Sacramento'",
                        fontWeight: '700'
                        }}>
                        {confirm <= 0 && guest?.tipoInvitacion != 3 ? "Confirmanos tu":  guest?.tipoInvitacion == 3 ? "": "Gracias por confirmar"}
                        </TextCarios>
                    </Grid>
                    <Grid item xs={1} md={2} sx={{display: "grid", alignContent: "center"}}></Grid>
                    <Grid item xs={10} md={8}>
                        {confirm <= 0 && guest?.tipoInvitacion != 3 ? (
                            <Typography
                            onClick={handleOpen}
                            variant='h6' sx={{
                                textAlign: "center", 
                                fontFamily: "serif",
                                color: "white", 
                                textShadow: "0px 2px 3px black",
                                fontSize: "30px !important",
                                fontFamily: "'Antic Didone'",
                                fontWeight: '700',
                                backdropFilter: "blur(15px)",
                                borderBottom: "1px solid white",
                                borderRadius: "5px",
                                boxShadow: "0px 5px 10px -3px white"
                            }}>Asistencia</Typography>
                        ):(<Grid container>
                        <Grid>
                        {/* <Typography
                            variant='h6' sx={{
                            textAlign: "center", 
                            fontFamily: "serif",
                            color: "white", 
                            letterSpacing: "-1px",
                            lineHeight: "20px"
                        }}>
                            Para mí eres muy importante, deseo que puedas confirmar tu asistencia al evento
                            
                            </Typography> */}
                            {/* Estamos felices de compartir con ustedes este día especial por lo cual 
                        <b style={{fontFamily: "'Sacramento'", fontSize: "35px", marginLeft: "5px", lineHeight: "0px"}}>
                            tu asistencia es importante</b> */}
                        </Grid>
                        <Grid item xs={12} sx={{display: "grid", justifyContent: "center"}}>
                        </Grid>
                        </Grid>)}
                    </Grid>
                    <Grid item xs={1} md={2} sx={{display: "grid", alignContent: "center"}}></Grid>
                    </Grid>
                    </Box>
                )
            }
        ]}
          className="maxHeight"
        />
      </Box>
    </Box>)
  }

export default Confirmation