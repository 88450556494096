import { Box, Typography } from "@mui/material"
import React, { useEffect, useState } from "react";
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import ReactAudioPlayer from 'react-audio-player';
import audio from '../../../audio/tmp_jeimi.mp3';

const PlayButton = ({}) => {
    let tmp = "tmp-1"
    const [playView, setPlayView] = useState(true);
    const [playMusic, setPlay] = useState(true);
    const [short, setShort] = useState("");
    const [notificacion, setNotificacion] = useState(true);

    const onPlay = state =>{
        setPlay(state)
        setPlayView(true)
        if(!state){
            document.getElementById('audioFile').play()
        }else{
            document.getElementById('audioFile').pause()
        }
    }

    useEffect(()=>{
        setTimeout(()=>{
            setNotificacion(false)
        },5000)
    },[])

    return(<Box className={`${tmp}-container-names`} sx={{position: "sticky", top: "10vh", zIndex: "15 !important"}}>
    <Box sx={{position: "absolute", right: "0px"}}>
        <Box className={`${tmp}-container-double-border-names`} 
            sx={{
                boxShadow: "0px 3px 18px -7px black",
                minHeight: "60px",
                maxWidth: "60px"

            }}>
        <Box className={`${tmp}-container-background-names`}
            sx={{
                cursor:"pointer",
                minHeight: "50px",
                maxWidth: "50px"
            }} onClick={() => onPlay(!playMusic)}>
            <Box sx={{
                color:"black !important",
                fontFamily: "'robotoregular'",
                position: "absolute",
                left: "-70px",
                background: "white",
                top: "85px",
                padding: "5px 10px",
                width: "90px",
                borderRadius: "10px 0px 30px 10px",
                fontFamily: "serif",
                fontSize: "14px",
                letterSpacing: "-1px",
                boxShadow: "0px 0px 10px -2px black",
                display: notificacion ? "block":"none"

            }}>Toca para reproducir</Box>
            <Typography className={`${tmp}-container-label-names`}>Jeimi</Typography>
            <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                {playMusic ? (<PlayCircleOutline sx={{ color: '#343333a1'}}/>):
                (<PauseCircleOutline sx={{ color: '#343333a1'}}/>)}
            </Box>
            <ReactAudioPlayer
                id="audioFile"
                src={audio}
                autoPlay={true}
            />
        </Box>
    </Box>
    </Box>
</Box>)
}

export default PlayButton