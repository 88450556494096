import { Box } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextRoboto, TextSouthwalles } from "."
import dresscode from '../../../img/traje_y_vestido-removebg-preview.png'

const Dresscode = ({bkImage}) => {
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer image={bkImage} speed={-5} />
        <ParallaxBannerLayer opacity={[0, 1.5]} translateY={[10, 10]}>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <TextSouthwalles sx={{
                    fontSize: "175px",
                    color: "white",
                    textShadow: "0px 6px 15px #ffa223"
                }}>Dresscode</TextSouthwalles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[45, 80]}>
            <TextRoboto sx={{
                fontSize: "52px",
                textShadow: "1px 1px 7px white",
                height: "70px",
                color: "white"
            }}>Formal</TextRoboto>
            <Box sx={{display: "grid", justifyContent: "center"}}>
                <Box sx={{
                    background: "white",
                    width: "150px",
                    height: "150px",
                    opacity: 0.7,
                    borderRadius: "50%"
                }}>
                    <img src={dresscode} style={{
                        width: "inherit",
                        padding: "20px"
                    }}/>
                </Box>
            </Box>
        </ParallaxBannerLayer>
      </ParallaxBanner>)
}

export default Dresscode