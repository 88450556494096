import { useEffect } from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useSnapCarousel } from "react-snap-carousel"
import { TextCarios, TextRobotoReg, VerMapa } from "."
import { Box, Grid, IconButton, Tooltip, styled } from "@mui/material"
import bkPadres from "../../../img/seccion_padres-removebg-preview.png"
import hotel_op2 from '../../../img/TRANSFERENCIA.png'
import hotel_op4 from '../../../img/SOBRE.png'

import { ContentCopy, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, LocationCity, Star } from "@mui/icons-material"

const Obsequios = ({n_cuenta, bkImage}) => {
    let carousel_adjust = {
        backgroundColor: 'transparent',
        fontSize: '50px',
        width: '100vw',
        height: '100vh',
        flexShrink: 0,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    let img_adjust = {
        height: "100vw",
        width: "100vw",
        maxWidth: "210px",
        maxHeight: "210px",
        objectFit: "contain"
    }

    let div_adjust = {
        paddingTop: "40px",
        height: "100vh",
        marginBottom: "5px",
        justifyItems: "center",
        display: "grid",
        alignContent: "start"
    }

    let ButtonActionCarousel = styled('div')({
        color: "black"
    })

    const Slide = ({titulo="", src=false, url="", desc="", cuenta, children, banco=false}) => {
        return(<li style={carousel_adjust}>
            <Box sx={div_adjust}>
                {children}
                <TextRobotoReg sx={{
                    fontSize: "17px",
                    textAlign: "center",
                    padding: "20px",
                    textTransform: "uppercase",
                    marginTop: "100px",
                    width: "70vw",
                    marginBottom: "5px",
                    borderBottom: "3px solid white",
                    color: "white"
                }}>
                    {titulo}
                </TextRobotoReg>
                <Box sx={{position: "relative"}}>
                    {src ? (<img src={src} style={img_adjust}/>): null}
                </Box>
                <TextRobotoReg sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    width: "75vw",
                    color: "white"
                }}>
                    {desc}
                </TextRobotoReg>
                    {cuenta ? (

                    <Tooltip onClick={()=>{
                        navigator.clipboard.writeText(cuenta)
                    }} title="copiar">
                    <TextRobotoReg sx={{
                        fontSize: "20px",
                        textTransform: "uppercase",
                        fontWeight: "500",
                        width: "65vw",
                        marginTop: "20px",
                        color: "white"

                    }}>
                        {cuenta}
                        <IconButton>
                            <ContentCopy sx={{color: "white"}}/>
                        </IconButton>
                    </TextRobotoReg>
                </Tooltip>
                    ): null}
                {banco ? (<TextRobotoReg sx={{
                    fontSize: "25px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    width: "65vw",
                    marginTop: "20px",
                    color: "white"

                }}>
                    {banco}
                </TextRobotoReg>): null}
                {url ? (<Box sx={{
                    color: "black",
                    background: "#f0e2ca",
                    fontSize: "20px",
                    marginTop: "30px",
                    width: "fit-content",
                    padding: "20px",
                    fontFamily: "robotoregular",
                    borderRadius: "20px",
                    textTransform: "uppercase"
                }} onClick={e=>{
                    window.open(url, '_blank')
                }}>
                    Ver regalos 
                </Box>): null}
            </Box>
        </li>)
    }

    const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();
  return (
    <ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "black" }}>
        <Box sx={{
                background: "black",
            "& div":{
                inset: "-15px -90px !important"
            }
        }}>
            <ParallaxBannerLayer translateX={[-5,-5]} image={bkImage} speed={-5} opacity={[0.8, 0.4]}/>
        </Box>
        <ParallaxBannerLayer>
            <Box>
                <ul
                    ref={scrollRef}
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        scrollSnapType: 'x mandatory'
                    }}
                >
                    <Slide 
                        src={hotel_op4}
                        titulo= "si deseas tener un detalle con nosotros puedes hacerlo a través de las siguientes maneras"
                        desc="Lluvia de sobres"
                    >
                        <TextCarios sx={{
                            position: "absolute",
                            top: "70px",
                            color: "white",
                            fontSize: "40px"
                        }}>
                            Obsequios
                        </TextCarios>
                    </Slide>
                    <Slide 
                        src={hotel_op2}
                        favorito={true}
                        titulo= "Transferencia bancaria"
                        desc="Karen Jacqueline Esquivel Rocha"
                        cuenta={n_cuenta ? n_cuenta: "not found"}
                        banco="Santander"
                    />
                </ul>
                <Grid container sx={{
                        position: "absolute",
                        top: "40%"
                    }}>
                        <Grid item xs={6}>
                            <ButtonActionCarousel onClick={() => prev()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginLeft: "10px"
                                }}>
                                    <KeyboardDoubleArrowLeft sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                        <Grid item xs={6} sx={{display: "grid", justifyContent: "end"}}>
                            <ButtonActionCarousel sx={{
                                textAlign: "end"
                            }} onClick={() => next()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginRight: "10px"
                                }}>
                                    <KeyboardDoubleArrowRight sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                    </Grid>
                    {/* <div>{activePageIndex + 1} / {pages.length}</div> */}
            </Box>
        </ParallaxBannerLayer>

      
    </ParallaxBanner>
    );
}

export default Obsequios