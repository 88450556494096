import { useEffect, useState } from "react"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useSnapCarousel } from "react-snap-carousel"
import { Box, Grid, styled } from "@mui/material"


import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, LocationCity, Star } from "@mui/icons-material"

const Galeria = ({bkImage}) => {
    const [imagenes, setImagenes] = useState([])    

    useEffect(()=>{
        console.log(bkImage)
    },[bkImage])

    let carousel_adjust = {
        backgroundColor: 'transparent',
        fontSize: '50px',
        width: '100vw',
        height: '100vh',
        flexShrink: 0,
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    let ButtonActionCarousel = styled('div')({
        color: "black"
    })

    const { scrollRef, pages, activePageIndex, next, prev, goTo } =
    useSnapCarousel();
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer>
            <Box>
                <ul
                    ref={scrollRef}
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        scrollSnapType: 'x mandatory'
                    }}
                >
                    {bkImage?.length > 0 ? bkImage.map((imagen, i_imagen) => {
                        return(<li style={{...carousel_adjust, position: "relative"}} key={i_imagen}>
                            <img
                                src={imagen}
                                style={{width: "100vw", height:'100vh', objectFit: "cover"}}
                            />
                        </li>)
                    }): null}
                </ul>
                {bkImage?.length > 0 ? (

                <Grid container sx={{
                        position: "absolute",
                        top: "40%"
                    }}>
                        <Grid item xs={6}>
                            <ButtonActionCarousel onClick={() => prev()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginLeft: "10px"
                                }}>
                                    <KeyboardDoubleArrowLeft sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                        <Grid item xs={6} sx={{display: "grid", justifyContent: "end"}}>
                            <ButtonActionCarousel sx={{
                                textAlign: "end"
                            }} onClick={() => next()}>
                                <Box sx={{
                                    display: "grid",
                                    background: "#f0e2ca",
                                    height: "40px",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    borderRadius: "50%",
                                    marginRight: "10px"
                                }}>
                                    <KeyboardDoubleArrowRight sx={{
                                        fontSize: "25px"
                                    }}/>
                                </Box>
                            </ButtonActionCarousel>
                        </Grid>
                    </Grid>
                ): null}
                    {/* <div>{activePageIndex + 1} / {pages.length}</div> */}
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>);
}


export default Galeria