import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TxtSubTitles } from "./Textos"
import { Box, Grid } from "@mui/material"
import hotel_op4 from '../../../img/sobre-removebg-preview.png'
import hotel_op5 from './assets/obsequios_regalo.png'
import img_ob_1 from './assets/obsequios_1.png'
import img_ob_2 from './assets/obsequios_2.png'

const Obsequios = () => {
    return (<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "#FFF2EE" }}>
        <ParallaxBannerLayer translateY={[9, 9]} opacity={[-1, 2.5]}>
            <TextCarios sx={{
                color: "#FF6D6D",
                padding: "0px",
                marginTop: "20px !important",
                fontSize: "70px"
            }}>Obsequios</TextCarios>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[22, 22]} opacity={[-1, 2.5]}>
            <Box sx={{padding: "50px"}}>
                <TxtSubTitles sx={{
                    color: "black",
                    padding: "0px",
                    marginTop: "20px !important",
                    fontSize: "20px",
                    fontFamily: "'balgin'",
                    letterSpacing: "4px",
                    lineHeight: "25px"
                }}>
                TU presencia y compañIa serA SIEMPRE EL MEJOR REGALO, SIN EMBARGO SI QUIEREN OBSEQUIARNOS ALGO MÁS, PUEDEN HACERLO A TRAVÉS DE:
                </TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[40, 50]} opacity={[-1, 2.5]}>
            <Box sx={{height: "40vh", width: "100%", padding: "1vh", textAlign: "center"}}>
                <Grid container sx={{padding: "40px"}}>
                    <Grid item xs={6} sx={{display: "grid", alignContent: "center"}}>
                        <img src={hotel_op5} style={{width: "100%", maxWidth: "320px", maxHeight: "300px"}}/>
                    </Grid>
                    <Grid item xs={6} sx={{display: "grid", alignContent: "center"}}>
                        <img src={hotel_op4} style={{width: "100%", maxWidth: "320px", maxHeight: "300px"}}/>
                    </Grid>
                </Grid>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer translateY={[0, 0]} opacity={[-1, 2.5]}>
            <Box sx={{position: "relative"}}>
                <Box>
                    <img src={img_ob_1} style={{height:"100vh"}}/>
                </Box>
                <Box sx={{position: "absolute", bottom: 0, right: 0}}>
                    <img src={img_ob_2} style={{width: "150px"}}/>
                </Box>
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>);
}

export default Obsequios