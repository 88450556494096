import { Box } from "@mui/material"
import { BloqueAnimacion, Seccion, TextAdega, TextAmazing, TextPlanner, TextRoboto, TextSouthwalles } from "."
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { useEffect, useState } from "react"

const Invitado = ({bkImage, invitado}) => {
    const [nombre, setNombre] = useState("")
    const [reservaciones, setReservacion] = useState(0)
    useEffect(()=>{
        if(invitado?.nombre){
            setNombre(invitado.nombre)
        }
        setReservacion(invitado.reservaciones)
    },[invitado])
    let phrase = "Con mucha ilusión queremos que formes parte de nuestra unión matrimonial"
    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "white" }}>
        <ParallaxBannerLayer scaleX={[0.99, 1]} image={bkImage} speed={-5} />
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[10, 10]} translateX={[30, -30]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px"}}>
                <TextAmazing sx={{
                    fontSize: "17px !important",
                    fontWeight: "bold"
                }}>{phrase.toUpperCase()}</TextAmazing>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[0.1, 2.1]} translateY={[28, 28]} translateX={[-30, 30]}>
            <Box sx={{display: "grid", justifyContent: "center", padding: "30px"}}>
                {/* <TextPlanner sx={{fontSize: "33px !important", marginBottom: "15px"}}>¡ACOMPAÑANOS!</TextPlanner> */}
                <Box sx={{
                    maxWidth: "280px",
                    minWidth: "280px",
                    padding: "0px 20px",
                    border: "2px solid #f7f3ed"
                }}>
                    <TextRoboto sx={{
                        fontSize: "26px !important",
                        fontWeight: "bold",
                        height: "34px",
                        color: "#f7f3ed"
                    }}>{nombre.toUpperCase()}</TextRoboto>
                    <TextRoboto sx={{
                        fontSize: "26px !important",
                        color: "#f7f3ed"
                    }}>{`(${reservaciones})`}</TextRoboto>
                </Box>
            </Box>
        </ParallaxBannerLayer>
        <Box sx={{
            position: "absolute",
            bottom: "20px",
            width: "100%",
            zIndex: "2",
        }}>
            <Box>
                <TextRoboto sx={{
                    fontSize: "42px !important",
                    color: "white",
                    fontWeight: "bold"
                }}>31 | 03 | 2024</TextRoboto>
            </Box>
        </Box>
    </ParallaxBanner>)
}

export default Invitado