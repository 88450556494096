import React, {useReducer} from 'react'
import multimediaReducer from './multimediaReducer'
import {MultimediaContext} from '../index'
import clientAxios  from '../../config/axios'
import tokenAuth from '../../config/tokenAuth'

import {
    OBTENER_MULTIMEDIA,
    REGISTRO_ERROR
} from '../../types'

const MultimediaState = props => {
    const initialState = {
        multimedia: []
    }

    const [ state, dispatch ] = useReducer(multimediaReducer, initialState)

    const obtener_filename = async evento => {
        try{
            // const result = await clientAxios.post(`/api/multimedia/${s_filename}`, data)
            const result = await clientAxios.post(`/api/multimedia/all/${evento}`)

            if(result?.data?.code != 200){
                return dispatch({
                    type: REGISTRO_ERROR,
                    payload: alert
                })
            }

            let result_image = await Promise.all(result?.data?.result ? result.data.result: false)

            dispatch({
                type: OBTENER_MULTIMEDIA,
                payload: result_image
            })
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }

            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }


    return(
        <MultimediaContext.Provider
            value={{
                multimedia: state.multimedia,
                obtener_filename
            }}
        >
            {props.children}
        </MultimediaContext.Provider>
    )
}

export default MultimediaState;