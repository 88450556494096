import { Box, Button, Typography, styled } from "@mui/material"
import React, { useContext, useEffect, useState } from "react";
import { MultimediaContext } from "../../../context";
import Banner from './Banner'
import TextoBiblico from "./TextoBiblico";
import Padres from "./Padres";
import { ParallaxBanner } from "react-scroll-parallax";
import Invitado from "./Invitado";
import Ubicaciones from "./Ubicaciones";
import Dresscode from "./Dresscode";
import Consideraciones from "./Consideraciones";
import Celebremos from "./Celebremos";
import Obsequios from "./Obsequios";
import Hospedaje from "./Hospedaje";
import Galeria from "./Galeria";
import Confirmation from "./Confirmacion";
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import ReactAudioPlayer from 'react-audio-player';
import audio from '../../../audio/audio-boda-1.mp3';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Landing from "../../routes/landing";

const Loading = () => {
  return(
    <Stack spacing={1} sx={{background: "#fff1e3", padding: "3vh"}}>
      <Skeleton variant="rounded" width="100%" height="10vh" />
      <Skeleton variant="rounded" width="100%" height="10vh" />
      <Skeleton variant="rounded" width="100%" height="30vh" />
      <Skeleton variant="rounded" width="100%" height="1vh" />
      <Skeleton variant="rounded" width="100%" height="1vh" />
      <Skeleton variant="rounded" width="100%" height="6vh" />
      <Skeleton variant="rounded" width="100%" height="30vh" />
      <Skeleton variant="rounded" width="100%" height="10vh" />
    </Stack>
  );
}

const PlayButton = ({}) => {
    let tmp = "tmp-1"
    const [playView, setPlayView] = useState(true);
    const [playMusic, setPlay] = useState(true);
    const [short, setShort] = useState("");
    const [notificacion, setNotificacion] = useState(true);

    const onPlay = state =>{
        setPlay(state)
        setPlayView(true)
        if(!state){
            document.getElementById('audioFile').play()
        }else{
            document.getElementById('audioFile').pause()
        }
    }

    useEffect(()=>{
        setTimeout(()=>{
            setNotificacion(false)
        },5000)
    },[])

    return(<Box className={`${tmp}-container-names`} sx={{position: "sticky", top: "10vh", zIndex: "15 !important"}}>
    <Box sx={{position: "absolute", right: "0px"}}>
        <Box className={`${tmp}-container-double-border-names`} sx={{boxShadow: "0px 3px 18px -7px black"}}>
        <Box className={`${tmp}-container-background-names`} sx={{cursor:"pointer"}} onClick={() => onPlay(!playMusic)}>
            <Box sx={{
                color:"black !important",
                fontFamily: "'robotoregular'",
                position: "absolute",
                left: "-70px",
                background: "white",
                top: "85px",
                padding: "5px 10px",
                width: "90px",
                borderRadius: "10px 0px 30px 10px",
                fontFamily: "serif",
                fontSize: "14px",
                letterSpacing: "-1px",
                boxShadow: "0px 0px 10px -2px black",
                display: notificacion ? "block":"none"

            }}>Toca para reproducir</Box>
            <Typography className={`${tmp}-container-label-names`}>J&M</Typography>
            <Box sx={{
                display: "grid",
                justifyContent: "center"
            }}>
                {playMusic ? (<PlayCircleOutline sx={{ color: '#343333a1'}}/>):
                (<PauseCircleOutline sx={{ color: '#343333a1'}}/>)}
            </Box>
            <ReactAudioPlayer
                id="audioFile"
                src={audio}
                autoPlay={true}
            />
        </Box>
    </Box>
    </Box>
</Box>)
}

export const TextSouthwalles = styled('p')({
    fontFamily: "'southwalles'",
    letterSpacing: "6px",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextRobotoReg = styled('p')({
    fontSize: "13px",
    width: "100%",
    textAlign: "center",
    color: "#030100",
    fontFamily: "robotoregular"
})

export const Seccion = styled('div')({
    height: "100vh",
    background: "#f7f3ed"
})

export const BloqueAnimacion = ({layers = []}) => {
    return(<Seccion>
        <ParallaxBanner
          layers={layers}
          className="maxHeight"
        />
    </Seccion>)
}

export const TextAmazing = styled('p')({
    fontFamily: "'amazingglight'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextHomesmile = styled('p')({
    fontFamily: "'homesmile'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextPlanner = styled('p')({
    fontFamily: "'planner'",
    color: "#313131",
    fontSize: "21px",
    textAlign: "center"
})

export const TextRoboto = styled('p')({
    fontFamily: "'lamora'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextCarios = styled('p')({
    fontFamily: "'carios'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextPlannet = styled('p')({
    fontFamily: "'plannet'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const TextAdega = styled('p')({
    fontFamily: "'adega'",
    color: "black",
    fontSize: "21px",
    textAlign: "center"
})

export const VerMapa = (url) => {  
    const redirectMap = () =>{
        console.log(url)
    }
    const VerMapaBtn = styled('div')({
        color: "black",
        background: "#f0e2ca",
        fontSize: "20px",
        marginTop: "30px",
        width: "fit-content",
        padding: "20px",
        fontFamily: "robotoregular",
        borderRadius: "20px"
    })

    return(<VerMapaBtn onClick={()=> window.open(url, '_blank')}>VER MAPA</VerMapaBtn>)
}


const Component = ({data = []}) => {
    const multimediaContext = useContext(MultimediaContext);
    const { obtener_filename, multimedia } = multimediaContext;

    const [multimedia_, setMultimedia_] = useState([])
    const [open, setOpen] = useState(false)
    const [nombre_corto, setNombre] = useState("")
    const [n_cuenta, setNcuenta] = useState("")
    const [secciones,   setSecciones]   = useState([])
    const [gallery, setGallery] = useState(false)

    useEffect(()=>{
        if(data?.evento?.festejados){
            let festejados = data.evento.festejados
            setNombre(festejados.nombreCorto)
        }
        obtener_filename(data.evento._id)
        if(data?.secciones?.length > 0){
            console.log(data.secciones)
            setSecciones(data.secciones)
        }

        if(data?.evento){
            let evento = data.evento
            if(evento?.n_cuenta){
                setNcuenta(evento.n_cuenta)
            }

            if(evento?.apartados){
                setSecciones(evento.apartados)
                console.log(evento.apartados)
                validate_gallery(evento.apartados)
            }
        }
    },[data])

    useEffect(()=>{
        if(multimedia?.length > 0){
            let result = {}
            multimedia.map((m_, m_i) => {
                if(m_?.s_filename){
                    let filename = m_?.s_filename, name = filename.split(".")
                    if(name[0].toLowerCase() == "banner"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            banner: array_multimedia.s_url
                        }
                    }if(name[0].toLowerCase() == "texto-biblbico"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            textoBiblico: array_multimedia.s_url
                        }
                    }if(name[0].toLowerCase() == "invitado"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            invitado: array_multimedia.s_url
                        }
                    }if(name[0].toLowerCase() == "ubicacion-religiosa"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            ubicacion: {
                                ...result.ubicacion,
                                religiosa: array_multimedia.s_url
                            }
                        }
                    }if(name[0].toLowerCase() == "ubicacion-recepcion"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            ubicacion: {
                                ...result.ubicacion,
                                recepcion: array_multimedia.s_url
                            }
                        }
                    }if(name[0].toLowerCase() == "dresscode"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            dresscode: array_multimedia.s_url
                        }
                    }if(name[0].toLowerCase() == "consideraciones"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            consideraciones: array_multimedia.s_url
                        }
                    }if(name[0].toLowerCase() == "consideraciones"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            consideraciones: array_multimedia.s_url
                        }
                    }if(name[0].toLowerCase() == "hospedaje_1" || 
                    name[0].toLowerCase() == "hospedaje_2" || name[0].toLowerCase() == "hospedaje_3"
                    || name[0].toLowerCase() == "hospedaje_4"
                    ){
                        let array_multimedia = multimedia[m_i]
                        if(result?.hospedaje){
                            result.hospedaje = [
                                ...result.hospedaje,
                                {
                                    index: name[0].toLowerCase() == "hospedaje_1" ? 1 :
                                    name[0].toLowerCase() == "hospedaje_2" ? 2: name[0].toLowerCase() == "hospedaje_3" ? 3:
                                    name[0].toLowerCase() == "hospedaje_4" ? 4: null,
                                    value: array_multimedia.s_url
                                }
                            ]
                        }else{
                            result.hospedaje = [{
                                index: name[0].toLowerCase() == "hospedaje_1" ? 1 :
                                name[0].toLowerCase() == "hospedaje_2" ? 2: name[0].toLowerCase() == "hospedaje_3" ? 3:
                                name[0].toLowerCase() == "hospedaje_4" ? 4: null,
                                value: array_multimedia.s_url
                            }]
                        }
                    }if(name[0].toLowerCase() == "celebremos-juntos"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            celebremos: array_multimedia.s_url
                        }
                    }if(name[0].toLowerCase() == "obsequios"){
                        let array_multimedia = multimedia[m_i]
                        result = {
                            ...result,
                            obsequios: array_multimedia.s_url
                        }
                    }
                    if(name[0].toLowerCase() == "galeria_1" || name[0].toLowerCase() == "galeria_2" 
                    || name[0].toLowerCase() == "galeria_3" || name[0].toLowerCase() == "galeria_4" || 
                    name[0].toLowerCase() == "galeria_5" || name[0].toLowerCase() == "galeria_6" ||
                    name[0].toLowerCase() == "galeria_7" || name[0].toLowerCase() == "galeria_8" ||
                    name[0].toLowerCase() == "galeria_9" || name[0].toLowerCase() == "galeria_10" ||
                    name[0].toLowerCase() == "galeria_11" || name[0].toLowerCase() == "galeria_12" ||
                    name[0].toLowerCase() == "galeria_13" || name[0].toLowerCase() == "galeria_14" ||
                    name[0].toLowerCase() == "galeria_15" || name[0].toLowerCase() == "galeria_16" ||
                    name[0].toLowerCase() == "galeria_17" || name[0].toLowerCase() == "galeria_17" ||
                    name[0].toLowerCase() == "galeria_19" || name[0].toLowerCase() == "galeria_20"
                    ){
                        let array_multimedia = multimedia[m_i]
                        if(result.galeria){
                            result.galeria = [
                                ...result.galeria,
                                array_multimedia.s_url
                            ]
                        }else{
                            result.galeria = [array_multimedia.s_url]
                        }
                    }if(name[0].toLowerCase() == "confirmar-asistencia"){
                        let array_multimedia = multimedia[m_i]

                        result = {
                            ...result,
                            confirmacion: array_multimedia.s_url
                            
                        }
                    }
                }
            })
            setMultimedia_({...result})
        }
        
    },[multimedia])

    const validate_gallery = (apartados = null) =>{
        if(apartados?.galeria_1||apartados?.galeria_2||apartados?.galeria_3||apartados?.galeria_4||apartados?.galeria_5||
            apartados?.galeria_6||apartados?.galeria_7||apartados?.galeria_8||apartados?.galeria_9||apartados?.galeria_10||
            apartados?.galeria_11||apartados?.galeria_12||apartados?.galeria_13||apartados?.galeria_14||apartados?.galeria_15||
            apartados?.galeria_16||apartados?.galeria_17||apartados?.galeria_18||apartados?.galeria_19||apartados?.galeria_20
        ){
            setGallery(true)
        }else{
            setGallery(false)
        }
    }

    return(<Box>
        {secciones["*button_play"] ? (<PlayButton/>): null}
        {secciones.banner       ? multimedia_?.banner ? (<Banner deadline="31 Mar 2024 17:30:00 UTC" nombre_corto={nombre_corto} bkImage={multimedia_.banner}/>): (<Loading/>): null}
        {secciones.texto_biblico       ? multimedia_?.textoBiblico ? (<TextoBiblico bkImage={multimedia_.textoBiblico}/>): (<Loading/>): null}
        {secciones?.padres      ? (<Padres evento={data.evento}/>): null}
        {secciones?.invitado    ? multimedia_?.invitado ? (<Invitado bkImage={multimedia_.invitado} invitado={data.invitado}/>): (<Loading/>): null}
        {secciones?.hospedaje_1 || secciones?.hospedaje_2 || secciones?.hospedaje_3 || secciones?.hospedaje_4 ? (<Hospedaje bkImage={multimedia_.hospedaje}/>): null}
        {secciones?.dresscode ? multimedia_?.dresscode ? (<Dresscode bkImage={multimedia_.dresscode}/>): (<Loading/>): null}
        {secciones?.obsequios ? multimedia_?.obsequios ? (<Obsequios n_cuenta={n_cuenta} bkImage={multimedia_.obsequios}/>): (<Loading/>): null}
        {secciones?.ubicacion_religiosa || secciones?.ubicacion_recepcion ? multimedia_?.ubicacion ? (<Ubicaciones imagenes={multimedia_.ubicacion}/>): (<Loading/>): null}
        {secciones?.consideraciones ? multimedia_?.consideraciones ? (<Consideraciones bkImage={multimedia_.consideraciones}/>): (<Loading/>): null}
        
        {multimedia_?.confirmacion ? (<Confirmation guest={data.invitado} bkImage={multimedia_.confirmacion} open={open} setOpen={setOpen}/>):(<Loading/>)}
        {multimedia_?.galeria && multimedia_?.galeria?.length > 0 ? (<Galeria bkImage={multimedia_.galeria}/>): (<Loading/>)}

        {/* {secciones?.confirmacion ? multimedia_?.confirmacion ? (<Confirmation guest={data.invitado} bkImage={multimedia_.confirmacion} open={open} setOpen={setOpen}/>):(<Loading/>): null}
        {gallery ? multimedia_?.galeria && multimedia_?.galeria?.length > 0 ? (<Galeria bkImage={multimedia_.galeria}/>): (<Loading/>): null} */}
        
        {secciones.celebremos_juntos ? multimedia_?.celebremos ? (<Celebremos bkImage={multimedia_.celebremos}/>):(<Loading/>): null}
        <Box sx={{background: "black", display: "grid", padding:"10px 10px"}}>
            <Typography sx={{fontFamily: "'robotoregular'", fontSize: "12px", textAlign: "center"}}>
                Desarrollado por <a href="https://convitte.com/" style={{
                    textDecoration: "none",
                    color: "#f6aaa6",
                    fontWeight: "700"
                }}>Convitte</a>
            </Typography>
        </Box>
        
        {/* {multimedia_?.banner ? (<PlayButton/>): (<Loading/>)}
        {multimedia_?.banner ? (<Banner deadline="31 Mar 2024 17:30:00 UTC" nombre_corto={nombre_corto} bkImage={multimedia_.banner}/>): (<Loading/>)}
        {multimedia_?.textoBiblico ? (<TextoBiblico bkImage={multimedia_.textoBiblico}/>): (<Loading/>)}
        {multimedia_?.galeria ? (<Padres evento={data.evento}/>): (<Loading/>)}
        {multimedia_?.invitado ? (<Invitado bkImage={multimedia_.invitado} invitado={data.invitado}/>): (<Loading/>)}
        {multimedia_?.galeria ? (<Hospedaje bkImage={multimedia_.hospedaje}/>): (<Loading/>)}
        {multimedia_?.dresscode ? (<Dresscode bkImage={multimedia_.dresscode}/>): (<Loading/>)}
        {multimedia_?.obsequios ? (<Obsequios n_cuenta={n_cuenta} bkImage={multimedia_.obsequios}/>): (<Loading/>)}
        {multimedia_?.ubicacion ? (<Ubicaciones imagenes={multimedia_.ubicacion}/>): (<Loading/>)}
        {multimedia_?.consideraciones ? (<Consideraciones bkImage={multimedia_.consideraciones}/>): (<Loading/>)}
        {multimedia_?.confirmacion ? (<Confirmation guest={data.invitado} bkImage={multimedia_.confirmacion} open={open} setOpen={setOpen}/>):(<Loading/>)}
        {multimedia_?.galeria && multimedia_?.galeria?.length > 0 ? (<Galeria bkImage={multimedia_.galeria}/>): (<Loading/>)}
        {multimedia_?.celebremos ? (<Celebremos bkImage={multimedia_.celebremos}/>):(<Loading/>)}
        {multimedia_?.celebremos ? (<Box sx={{background: "black", display: "grid", padding:"10px 10px"}}>
            <Typography sx={{fontFamily: "'robotoregular'", fontSize: "12px", textAlign: "center"}}>
                Desarrollado por <a href="https://convitte.com/" style={{
                    textDecoration: "none",
                    color: "#f6aaa6",
                    fontWeight: "700"
                }}>Convitte</a>
            </Typography>
        </Box>):(<Loading/>)} */}

    </Box>)
}

export default Component