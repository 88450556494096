import { Box, styled } from "@mui/material"
import img_1 from "./assets/textoYrecuerdos_1.png"
import img_2 from "./assets/texto_2.png"
import { TxtSubTitles } from "./Textos"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

const Frases = ({padre, madre}) => {

    return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "#FFF2EE" }}>
        <ParallaxBannerLayer opacity={[-1, 2.5]} translateY={[30, 40]}>
            <Box sx={{position: "absolute", top: 0, left: 0, width: "100%"}}>
                <img src={img_1} width="100%"/>
            </Box>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 2.5]} translateY={[38, 40]}>
            <TxtSubTitles sx={{
                fontFamily: "'balgin'",
                color: "black",
                fontSize: "22px !important",
                letterSpacing: "5px",
                padding: "8% 12%",
                lineHeight: 1.6
            }}>ERES NUESTRA MAYOR CREACIÓN, NUESTRA OBRA MÁS PERFECTA, NUESTRO MAYOR TESORO. </TxtSubTitles>
        </ParallaxBannerLayer>
        <ParallaxBannerLayer opacity={[-1, 2.5]} translateY={[55, 55]}>
            <Box sx={{position: "relative"}}>
                <TxtSubTitles sx={{
                    fontFamily: "'balgin'",
                    color: "black",
                    fontSize: "16px !important",
                    letterSpacing: "5px",
                    padding: "8% 12%",
                    lineHeight: 1.6
                }}>ATENTAMENTE</TxtSubTitles>
                <img style={{
                    position: "absolute",
                    top: -15,
                    left: "50%"
                }} src={img_2} width="140px"/>
                <TxtSubTitles sx={{
                    fontFamily: "'Buff'",
                    color: "#FF6D6D",
                    textTransform: "none",
                    fontSize: "56px !important",
                    padding: "0% 12%",
                }}>{madre} & {padre}</TxtSubTitles>
            </Box>
        </ParallaxBannerLayer>
    </ParallaxBanner>)
}

export default Frases;