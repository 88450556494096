/* eslint-disable import/no-anonymous-default-export */
import {
OBTENER_MULTIMEDIA
} from '../../types'

export default (state, action) => {
    switch(action.type){
        case OBTENER_MULTIMEDIA:
            return{
                multimedia: action.payload
            }
        default:
            return state;
    }
}