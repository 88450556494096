import './styles'
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context";
import Landing from "../routes/landing";
import Template1 from '../Template/jaqmik'
import Template2 from '../Template/adkar'
import Template3 from '../Template/jeimi'
import Template4 from '../Template/katherine'

const Template = () => {
    const [id_plantilla, setPlantilla] = useState(-1)
    const [data, setData] = useState({})

    const authContext = useContext(AuthContext);
    const { authInvitado, informacion } = authContext;

    useEffect(() => {
        authInvitado();
    },[])

    useEffect(() => {
        if(informacion){
            setData(informacion)
        }
    },[informacion])

    useEffect(()=>{
        if(Object.keys(data).length !== 0 && data.constructor === Object){
            setPlantilla(
                data?.id_plantilla || data.id_plantilla == 0 ?
                    data.id_plantilla: -1
            )
        }
    },[data])

    if(id_plantilla > -1){
        switch(id_plantilla){
            case 1:
                return(<Template1 data={data}/>)
            case 2:
                return(<Template2 data={data}/>)
            case 3:
                return(<Template3 data={data}/>)
            case 4:
                return(<Template4 data={data}/>)
            default:
               return(<Landing/>) 
        }
    }else{
        return(<Landing/>)
    }
}

export default Template;
