import { Box, styled } from "@mui/material"
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { TextCarios, TxtSubTitles } from "./Textos"
import { CalendarMonth, Place, Schedule } from "@mui/icons-material"
import img_2 from "./assets/recepcion_2.png"

const VerMapaBtn = styled('div')({
    color: "black",
    background: "#FFDBA1",
    width: "fit-content",
    padding: "5px",
    borderRadius: "20px",
    marginTop: "25px",
    minWidth: "150px"
})

const Recepcion = ({bkImage}) => {
    let dir = "Gama 317, Col. Los cometas, Juárez, N.L"
    // let nombre_dir  = 'La amatista eventos'
    if(bkImage){
        return(<ParallaxBanner className="maxHeight" style={{ aspectRatio: '2 / 1', background: "#FFF2EE"}}>
            <ParallaxBannerLayer translateY={[0, 0]} opacity={[-1, 2.5]} style={{position: "relative", height: "100vh"}}>
                <Box sx={{height: "40vh", width: "100%", padding: "1vh", marginTop: "20px"}}>
                    <img src={bkImage} style={{width: "100%", height: "35vh"}}/>
                </Box>
                <TextCarios sx={{color: "#FF6D6D", padding: "0px", marginTop: "10px !important", fontSize: "50px"}}>
                    Recepción
                </TextCarios>
                {/* <TxtSubTitles sx={{color: "black", padding: "0px", fontSize: "15px", fontWeight: "bold"}}>
                    {nombre_dir}
                </TxtSubTitles> */}
                <Box sx={{marginTop: "20px"}}>
                    <Box sx={{marginTop: "25px"}}>
                        <Box sx={{display: "block", padding: "0px 50px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "16px",
                                padding: "0px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "'balgin'"
                            }}><Place sx={{color: "#A76C40", fontSize: "24px", marginRight: "10px"}}/>{dir}</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: "25px"}}>
                        <Box sx={{display: "block", padding: "0px 30px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "16px",
                                padding: "0px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "'balgin'"
                            }}><CalendarMonth sx={{color: "#A76C40", fontSize: "24px", marginRight: "15px"}}/>Domingo 28 de julio 2024</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{marginTop: "25px"}}>
                        <Box sx={{display: "block", padding: "0px 30px"}}>
                            <TxtSubTitles sx={{
                                color: "black",
                                fontSize: "16px",
                                padding: "0px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "'balgin'"
                            }}><Schedule sx={{color: "#A76C40", fontSize: "24px", marginRight: "15px"}}/>4:30 p.m.</TxtSubTitles>
                        </Box>
                    </Box>
                    <Box sx={{display: "grid", justifyContent: "center", zIndex: 3}}>
                        <VerMapaBtn>
                            <TxtSubTitles onClick={e=>{
                                let map_dir = "https://www.google.com.mx/maps/place/Gama+317,+Monte+Kristal,+67250+Arboledas+de+San+Roque,+N.L./@25.6084387,-100.1530149,17z/data=!4m16!1m9!3m8!1s0x8662c3ec2494de15:0x96e0317958c2c6e2!2sGama+317,+Monte+Kristal,+67250+Arboledas+de+San+Roque,+N.L.!3b1!8m2!3d25.6084074!4d-100.1529178!10e5!16s%2Fg%2F11v0yl8clj!3m5!1s0x8662c3ec2494de15:0x96e0317958c2c6e2!8m2!3d25.6084074!4d-100.1529178!16s%2Fg%2F11v0yl8clj?entry=ttu"
                                var win = window.open(map_dir, '_blank');
                                // Cambiar el foco al nuevo tab (punto opcional)
                                win.focus();
                            }} sx={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                padding: "5px"
                            }}>VER MAPA</TxtSubTitles>
                        </VerMapaBtn>
                    </Box>
                </Box>
                <Box sx={{position: "absolute", bottom: 60, right: 0, width: "100%", display: "grid", justifyItems: "end", zIndex:-3}}>
                    <img src={img_2} width="60%" style={{maxWidth: "180px", opacity: 0.6}}/>
                </Box>
            </ParallaxBannerLayer>
            {/* <ParallaxBannerLayer translateY={[0, 0]} opacity={[0.4, 1.5]} style={{position: "relative", height: "100vh"}}>
            </ParallaxBannerLayer> */}
      </ParallaxBanner>)
    }else{return(<Box></Box>)}
}

export default Recepcion