import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context";
import url from '../../img/banner-login.jpg'

import {useParams} from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";

const Acceso = () => {
    const authContext = useContext(AuthContext);
    const { login, authInvitado, informacion } = authContext;
    const {code, codeManually} = useParams();

    useEffect(()=>{
        authInvitado()
        if(code){
            setTimeout(()=>{
                login(code)
                setCodigo(code)
            },1000)
        }
        if(codeManually){
            console.log(codeManually)
            setCodigo(codeManually)
        }
        // console.log(usuarioAutenticado)
    },[])

    useEffect(()=>{
        if(informacion?.success){
            console.log(informacion?.evento?.festejados)

            let festejados = informacion?.evento?.festejados ? informacion.evento.festejados: null
            if(festejados){
                let evento = festejados?.festejado ? festejados: 
                    festejados?.nombreCorto ? 
                    festejados?.nombreCorto: "evento-especial";

                    let nombreSinEspacios =  evento.split(' ').join('')
                    
                    if(nombreSinEspacios && typeof nombreSinEspacios == "string"){
                        window.location.href = `/invitacion/${nombreSinEspacios.toLowerCase()}`
                    }
            }
        }
    },[informacion])
    
    const [codigo, setCodigo] = useState("");
    return(<Box>
        <Box sx={{
            height: '92vh',
            backgroundImage: `url("${url}")`,
            backgroundSize: "cover",
            display: 'grid',
            alignContent: 'center',
        }}>
            <Box sx={{
                width: '100vw',
                padding: '2vw 0px',
                borderRadius: "5px",
                justifyContent: "center",
                textAlign: "center",
                display: 'grid'
            }}>
                <Box sx={{
                    width: "70vw",
                    maxWidth: "300px",
                    background: 'white',
                    borderRadius:"5px"
                }}>
                    <Typography sx={{fontWeight: "bold",fontSize: "23px",fontFamily:"'Antic Didone'", margin: '10px 0px'}}>Invitación Cordial</Typography>
                    {codeManually ? (<Typography sx={{marginTop: '30px', fontSize: "10px"}}>Da click en el botón de acceder para ver invitación</Typography>
                    ): null}
                    {codigo && code ? (<InfinitySpin id="infinity"
                            width='200'
                            color="#e3c81d"
                        />):(<Box>
                        <Box hidden={codeManually ? true: false} margin="0px 40px">
                            <TextField type={codeManually ? "hidden": "text"} onChange={e => setCodigo(e.target.value)} value={codigo}/>
                        </Box>
                        <Button sx={{
                            width:"100%", 
                            color: "white !important", 
                            marginTop: !codeManually ? "20px": "0px",
                            background: "black",
                            borderRadius: "0px 0px 5px 5px",
                            "&:hover":{
                                background: "#a6967d !important"
                            }
                        }} onClick={() => codigo ? login(codigo): null}>Acceder</Button>
                        </Box>)}
                </Box>
            </Box>
        </Box>
        <Box sx={{
            background: 'black !important', 
            height: '8vh',
            display: "grid",
            alignContent: "center",
            fontSize: "10px",
            justifyContent: "center"
        }} item xs={12}>
            <Typography sx={{color: 'white', fontSize: '12px'}}>Desarrollado por Convite © 2023</Typography>
        </Box>
    </Box>)
}

export default Acceso